import * as filterActionInterfaces from './filter.actions.interfaces';
import {
  FILTER_DELETE_PENDING,
  FILTER_RESET,
  FILTER_SELECTION_PENDING,
  FILTER_SELECTION_SUCCESS
} from './filter.actions.constants';
import { IFilterCriteria, IFilterSelection } from './filter.state.interfaces';

export const filterSelectionPendingAction = (
  filterChange: IFilterSelection
): filterActionInterfaces.IFilterSelectionPending => ({
  type: FILTER_SELECTION_PENDING,
  meta: { filterChange }
});

export const filterSelectionSuccessAction = (
  filterCriteria: IFilterCriteria[]
): filterActionInterfaces.IFilterSelectionSuccess => ({
  type: FILTER_SELECTION_SUCCESS,
  meta: { filterCriteria }
});

export const filterResetAction = (): filterActionInterfaces.IFilterReset => ({
  type: FILTER_RESET
});

export const filterDeletePendingAction = (filterName: string): filterActionInterfaces.IFilterDeletePending => ({
  type: FILTER_DELETE_PENDING,
  meta: { filterName }
});
