import {
  IFileCaptureError,
  IFileCapturePending,
  IFileCaptureSuccess,
  IResetCustomerPoFile,
  IResetCustomerPoNumber,
  ISetCustomerPoFile,
  ISetCustomerPoNumber
} from './customerPo.action.interfaces';
import {
  FILE_CAPTURE_ERROR,
  FILE_CAPTURE_PENDING,
  FILE_CAPTURE_SUCCESS,
  RESET_CUSTOMER_PURCHASE_ORDER_FILE,
  RESET_CUSTOMER_PURCHASE_ORDER_NUMBER,
  SET_CUSTOMER_PURCHASE_ORDER_FILE,
  SET_CUSTOMER_PURCHASE_ORDER_NUMBER
} from './customerPo.actions.constants';

export const setCusomerPoFileAction = (
  orderId: number,
  file: string | undefined,
  fileName: string
): ISetCustomerPoFile => ({
  type: SET_CUSTOMER_PURCHASE_ORDER_FILE,
  meta: {
    orderId,
    file,
    fileName
  }
});

export const setCusomerPoNumberAction = (orderId: number, customerPoNumber: string): ISetCustomerPoNumber => ({
  type: SET_CUSTOMER_PURCHASE_ORDER_NUMBER,
  meta: {
    orderId,
    customerPoNumber
  }
});

export const resetCustomerPoFileAction = (orderId: number): IResetCustomerPoFile => ({
  type: RESET_CUSTOMER_PURCHASE_ORDER_FILE,
  meta: { orderId }
});

export const resetCustomerPoNumberAction = (orderId: number): IResetCustomerPoNumber => ({
  type: RESET_CUSTOMER_PURCHASE_ORDER_NUMBER,
  meta: { orderId }
});

/**
 * Action dispatched to process a file capture via input tag on Po upload
 *
 * @param event - The sythetic file capture event given by an input tag
 * @param orderId - The order id processed
 * @param processFileFunction - A function that will be called after the file is converted to binary string
 */
export const fileCapturePendingAction = (
  event: React.ChangeEvent<HTMLInputElement>,
  orderId: number,
  processFileFunction: (orderId: number, base64File: string, fileName: string) => void
): IFileCapturePending => ({
  type: FILE_CAPTURE_PENDING,
  meta: {
    orderId,
    event,
    processFileFunction
  }
});

export const fileCaptureSuccessAction = (): IFileCaptureSuccess => ({
  type: FILE_CAPTURE_SUCCESS
});

export const fileCaptureErrorAction = (): IFileCaptureError => ({
  type: FILE_CAPTURE_ERROR
});
