import { IApiRequest, fetchApi } from '../../core/auth/fetchApiHelper';
import { processConfig } from '../../environment.constants';
import { IFilterCriteria } from '../Filter/filter.state.interfaces';

export const getProducts = async (filterCriteria: IFilterCriteria) => {
  const request: IApiRequest = {
    url: `${processConfig.baseGatewayUrl}/inventory-svc/v1.0/Product/filter-products`,
    method: 'POST',
    contentType: 'application/json',
    requireAccessToken: true,
    body: filterCriteria
  };

  const response = await fetchApi(request);
  return response;
};

export const getCertificate = async (url: string, token: string) => {
  const headers = new Headers({
    'Content-Type': 'application/json'
  });
  const errorRes = {
    payload: null,
    statusCode: 500,
    message: 'Error Downloading Certificate'
  };
  headers.append('Authorization', `Bearer ${token}`);
  headers.append('Access-Control-Allow-Origin', '*');
  const response = await fetch(
    `${processConfig.baseGatewayUrl}/inventory-svc/v1.0/Product/get-certification?blobUrl=${url}`,
    {
      headers,
      method: 'GET'
    }
  )
    .then(async (response: any) => {
      if (response.ok) {
        const blob = await response.blob();
        const res = {
          payload: blob,
          statusCode: 200
        };
        return res;
      }

      return errorRes;
    })
    .catch((error: any) => {
      throw error;
    });

  return response;
};

export default getProducts;
