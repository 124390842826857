import React from 'react';
import { useSelector } from 'react-redux';

import { Backdrop, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { IState } from '../../../state/store.state';
import { impersonatedUserSelector, isImpersonationPendingSelector } from '../customer.selectors';

const ShoppingFor = () => {
  const classes = useStyles();
  const impersonatedUser = useSelector((state: IState) => impersonatedUserSelector(state));
  const isImpersonatePending = useSelector((state: IState) => isImpersonationPendingSelector(state));

  const shoppingRender = impersonatedUser ? (
    <Typography color="secondary">
      Shopping for {impersonatedUser.displayName + ' / ' + impersonatedUser.company}{' '}
    </Typography>
  ) : (
    <></>
  );

  return (
    <>
      <Backdrop className={classes.backdrop} open={isImpersonatePending}>
        <>
          <Typography>Impersonating {impersonatedUser?.displayName}... please wait</Typography>
        </>
      </Backdrop>
      {shoppingRender}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    }
  })
);

export default ShoppingFor;
