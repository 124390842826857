import { IApiRequest, fetchApi } from '../../core/auth/fetchApiHelper';
import { processConfig } from '../../environment.constants';

import { IAadGroup } from './authorization.state.interface';

export const getUserGroups = async (userId: string): Promise<IAadGroup[]> => {
  const request: IApiRequest = {
    url: `${processConfig.baseGatewayUrl}/core-svc/v2.0/Graph/aad-groups-by-user/${userId}`,
    method: 'GET',
    contentType: 'application/json',
    requireAccessToken: true
  };

  const response = await fetchApi(request);
  return response.payload as IAadGroup[];
};
