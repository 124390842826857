import React from 'react';
import { useSelector } from 'react-redux';

import { Box, CircularProgress, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const AlertDialog = React.lazy(() => import('../../../app/Notification/Components/AlertDialog'));
const Notification = React.lazy(() => import('../../../app/Notification/Notification'));
const ShoppingCartTimerDialog = React.lazy(() => import('../../../app/Shopping/Components/ShoppingCartTimerDialog'));

import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import Footer from '../Footer';
import Header from '../Header';
import Routes from '../router/Routes';
import ShoppingFor from '../../../app/Customer/Components/ShoppingFor';
import Suspense from '../Suspense';
import HeaderProfile from '../HeaderProfile';
import { impersonatedUserSelector } from '../../../app/Customer/customer.selectors';
import { IState } from '../../../state/store.state';

const LayoutApp = () => {
  const classes = useStyles();
  const impersonatedUser = useSelector((state: IState) => impersonatedUserSelector(state));
  const { inProgress } = useMsal();
  const msalInProgress = inProgress !== InteractionStatus.None;

  const layout = (
    <React.Suspense fallback={<Suspense />}>
      <Box className={classes.siteContainer}>
        <Box className={classes.shoppingFor} height={impersonatedUser ? '20px' : 0}>
          <ShoppingFor />
        </Box>
        <Box className={classes.headerProfileContainer}>
          <HeaderProfile />
        </Box>
        <Box className={classes.headerContainer}>
          <Header />
        </Box>
        <Box className={classes.siteContent}>
          <Routes />
        </Box>
        <Box className={classes.footerContainer}>
          <Footer />
        </Box>
        <AlertDialog />
        <Notification />
        <ShoppingCartTimerDialog />
      </Box>
    </React.Suspense>
  );

  const spinner = (
    <Box className={classes.siteContainer}>
      <Box flex={1} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <CircularProgress size={65} />
      </Box>
    </Box>
  );

  return msalInProgress ? spinner : layout;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerProfileContainer: {
      backgroundColor: '#333333',
      height: 25,
      borderTopLeftRadius: '6px',
      borderTopRightRadius: '6px'
    },
    headerContainer: {
      height: 70
    },
    siteContainer: {
      display: 'flex',
      flex: 1,
      minHeight: '98vh',
      flexGrow: 1,
      flexDirection: 'column'
    },
    siteContent: {
      display: 'flex',
      flex: 1,
      flexGrow: 1
    },
    shoppingFor: {
      display: 'flex',
      justifyContent: 'center'
    },
    footerContainer: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        alignItems: 'normal'
      }
    }
  })
);

export default LayoutApp;
