import { ISetAppName, ISetViewMode } from './layout.actions.interfaces';
import { SET_APP_NAME, SET_VIEW_MODE } from './layout.constants';
import { ILayoutState } from './layout.state.interfaces';

export const initialState: ILayoutState = {
  appName: '',
  isDesktopMode: false
};

const setAppName = (state: ILayoutState, action: ISetAppName): ILayoutState => {
  return { ...state, appName: action.meta.appName };
};

const setViewMode = (state: ILayoutState, action: ISetViewMode): ILayoutState => {
  return { ...state, isDesktopMode: action.meta.isDesktopMode };
};

type handledActionTypes = ISetAppName | ISetViewMode;

export const appReducer = (state: ILayoutState = initialState, action: handledActionTypes): ILayoutState => {
  switch (action.type) {
    case SET_APP_NAME:
      return setAppName(state, action);
    case SET_VIEW_MODE:
      return setViewMode(state, action);
  }
  return state;
};

export default appReducer;
