import {
  GET_AUTHORIZATION_ERROR,
  GET_AUTHORIZATION_PENDING,
  GET_AUTHORIZATION_SUCCESS
} from './authorization.actions.constants';
import {
  IGetAuthorizationError,
  IGetAuthorizationPending,
  IGetAuthorizationSuccess
} from './authorization.actions.interface';
import { AuthorizationStatus } from './authorization.enum';
import { IAuthorizationState } from './authorization.state.interface';

const initialState: IAuthorizationState = {
  authorizationStatus: AuthorizationStatus.InitialState,
  groups: null
};

const authorizationReducer = (
  state: IAuthorizationState = initialState,
  action: IGetAuthorizationPending | IGetAuthorizationSuccess | IGetAuthorizationError
): IAuthorizationState => {
  switch (action.type) {
    case GET_AUTHORIZATION_PENDING:
      return { ...state, authorizationStatus: AuthorizationStatus.Pending };
    case GET_AUTHORIZATION_SUCCESS:
      return {
        ...state,
        authorizationStatus: action.payload.authorizationStatus,
        groups: action.payload.groups
      };
    case GET_AUTHORIZATION_ERROR:
      return { ...state, authorizationStatus: AuthorizationStatus.Error };
  }

  return state;
};

export default authorizationReducer;
