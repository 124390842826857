export const SHOPPING_STARTUP = '[Shopping]/SHOPPING_STARTUP';
export const SHOPPING_ADD_TO_CART_PENDING = '[Shopping]/SHOPPING_ADD_TO_CART_PENDING';
export const SHOPPING_ADD_TO_CART_SUCCESS = '[Shopping]/SHOPPING_ADD_TO_CART_SUCCESS';
export const SHOPPING_REMOVE_FROM_CART_PENDING = '[Shopping]/SHOPPING_REMOVE_FROM_CART_PENDING';
export const SHOPPING_REMOVE_FROM_CART_SUCCESS = '[Shopping]/SHOPPING_REMOVE_FROM_CART_SUCCESS';
export const SHOPPING_REMOVE_FROM_CART_ERROR = '[Shopping]/SHOPPING_REMOVE_FROM_CART_ERROR';
export const SHOPPING_PURCHASE_REQUEST_PENDING = '[Shopping]/SHOPPING_PURCHASE_REQUEST_PENDING';
export const SHOPPING_PURCHASE_REQUEST_SUCCESS = '[Shopping]/SHOPPING_PURCHASE_REQUEST_SUCCCESS';
export const SHOPPING_PURCHASE_REQUEST_ERROR = '[Shopping]/SHOPPING_PURCHASE_REQUEST_ERROR';
export const SHOPPING_REMOVE_ALL_FROM_CART_PENDING = '[Shopping]/SHOPPING_REMOVE_ALL_FROM_CART_PENDING';
export const SHOPPING_REMOVE_ALL_FROM_CART_SUCCESS = '[Shopping]/SHOPPING_REMOVE_ALL_FROM_CART_SUCCESS';
export const SHOPPING_REMOVE_ALL_FROM_CART_ERROR = '[Shopping]/SHOPPING_REMOVE_ALL_FROM_CART_ERROR';
export const SHOPPING_TOGGLE_EXPIRED_CART = '[Shopping]/SHOPPING_TOGGLE_EXPIRED_CART';
export const SHOPPING_CART_MONITOR_START = '[Shopping]/SHOPPING_CART_MONITOR_START';
export const SHOPPING_CART_EXPIRATION_MONITOR_START = '[Shopping]/SHOPPING_CART_EXPIRATION_MONITOR_START';
export const SHOPPING_GET_CART_PENDING = '[Shopping]/SHOPPING_GET_CART_PENDING';
export const SHOPPING_GET_CART_SUCCESS = '[Shopping]/SHOPPING_GET_CART_SUCCESS';
export const SHOPPING_GET_CART_ERROR = '[Shopping]/SHOPPING_GET_CART_ERROR';
export const SHOPPING_UPDATE_CART_ITEMS_AT_SERVER = '[Shopping]/SHOPPING_UPDATE_CART_ITEMS_AT_SERVER';
export const SHOPPING_CART_TOGGLE = '[Shopping]/SHOPPING_CART_TOGGLE';
export const SET_CART_COUNT = '[Shopping]/SET_CART_COUNT';
export const SHOPPING_VIEW_CART_PENDING = '[Shopping]/VIEW_CART_PENDING';
