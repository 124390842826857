import { IApiRequest, fetchApi } from '../../core/auth/fetchApiHelper';
import { processConfig } from '../../environment.constants';
import { ICustomerPoFile, ICustomerPoNumber } from '../CustomerOrderInfo/customerPo.state.interface';
export const getOrderHistory = async (userId: string, beginDate: Date, endDate: Date, searchText: string) => {
  const request: IApiRequest = {
    url: `${
      processConfig.baseGatewayUrl
    }/shopping-svc/v1.0/Order/get-orders-by-user-id?userId=${userId}&beginDate=${beginDate.toISOString()}&endDate=${endDate.toISOString()}&searchText=${searchText}`,
    method: 'GET',
    contentType: 'application/json',
    requireAccessToken: true
  };

  const response = await fetchApi(request);
  return response;
};

export const updateCustomerPoFile = async (customerPoFile: ICustomerPoFile) => {
  const request: IApiRequest = {
    url: `${processConfig.baseGatewayUrl}/shopping-svc/v1.0/Order/update-customer-purchase-order-file`,
    method: 'POST',
    contentType: 'application/json',
    requireAccessToken: true,
    body: customerPoFile
  };

  const response = await fetchApi(request);
  return response;
};

export const updateCustomerPoNumber = async (customerPoNumber: ICustomerPoNumber) => {
  const request: IApiRequest = {
    url: `${processConfig.baseGatewayUrl}/shopping-svc/v1.0/Order/update-customer-purchase-order-number`,
    method: 'POST',
    contentType: 'application/json',
    requireAccessToken: true,
    body: customerPoNumber
  };

  const response = await fetchApi(request);
  return response;
};
