import { createSelector } from 'reselect';

import { IState } from '../../state/store.state';

import { IOrderHistoryState } from './order.history.state.interfaces';

const orderHistorySelector = (state: IState) => state.orderHistory;

export const isOrderHistoryLoadPendingSelector = createSelector(
  [orderHistorySelector],
  (orderHistory: { isOrderHistoryLoadPending: boolean }) => orderHistory.isOrderHistoryLoadPending
);

export const getOrderCountWithNoPurchaseOrderSelector = createSelector(
  [orderHistorySelector],
  (orderHistory: IOrderHistoryState) =>
    orderHistory.orders ? orderHistory.orders.filter((f) => !f.customerPoLocation).length : 0
);

export const getOrdersSelector = (state: IState) => state.orderHistory.orders;

export const isPoUpdatePendingSelector = createSelector(
  [orderHistorySelector],
  (orderHistory: { showCustomerPurchaseOrderDialog: boolean }) => orderHistory.showCustomerPurchaseOrderDialog
);

export const showOrderDocumentsDialogSelector = createSelector(
  [orderHistorySelector],
  (orderHistory: { showOrderDocumentsDialog: boolean }) => orderHistory.showOrderDocumentsDialog
);

export const showOrderDocumentsDialogMobileSelector = createSelector(
  [orderHistorySelector],
  (orderHistory: { showOrderDocumentsDialogMobile: boolean }) => orderHistory.showOrderDocumentsDialogMobile
);

export const getOrderDocumentsSelector = (state: IState) => {
  const order = state.orderHistory.orders.filter((f) => f.orderId === state.orderHistory.orderDocumentDialogOrderId)[0];

  const uniqueDocuments = order.documents.filter(
    (d, i) => order.documents.findIndex((s) => s.location === d.location) === i
  );
  return uniqueDocuments;
};

export const getCheckedOrderDocumentsSelector = (state: IState) => {
  const order = state.orderHistory.orders.filter((f) => f.orderId === state.orderHistory.orderDocumentDialogOrderId)[0];

  return order.documents.filter((d) => d.isDocumentChecked === true);
};

export const getPoAlertMessageSelector = createSelector([orderHistorySelector], (orderHistory) => {
  const ordersNeedPo = orderHistory.orders.filter((f) => f.customerPoFileName === null || f.customerPoFileName === '');

  const message =
    ' must have purchase order info uploaded to enable Metal Exchange to fulfill the order.  Thank you for your business!';

  if (ordersNeedPo.length === 1) {
    return `Order "${ordersNeedPo[0].customerPoNumber.toString()}"${message}`;
  } else if (ordersNeedPo.length > 1) {
    return `Orders "${ordersNeedPo.map((o) => o.customerPoNumber).join('", "')}"${message}`;
  }

  return '';
});

export const getHeadersSelector = createSelector([orderHistorySelector], (orderHistory) => {
  return orderHistory.headers;
});

export const beginDateFilterSelector = createSelector([orderHistorySelector], (orderHistory): Date => {
  return orderHistory.filters.beginDate ? orderHistory.filters.beginDate : getDefaultStartDate();
});
export const endDateFilterSelector = createSelector([orderHistorySelector], (orderHistory): Date => {
  return orderHistory.filters.endDate ? orderHistory.filters.endDate : new Date();
});

export const dateFilterDisplaySelector = createSelector([orderHistorySelector], (orderHistory): string => {
  const start = orderHistory.filters.beginDate ? orderHistory.filters.beginDate : getDefaultStartDate();
  const end = orderHistory.filters.endDate ? orderHistory.filters.endDate : new Date();

  return '(' + new Date(start).toLocaleDateString() + ' to ' + new Date(end).toLocaleDateString() + ')';
});

export const isFilteredSelector = createSelector([orderHistorySelector], (orderHistory): boolean => {
  return orderHistory.filters.isFiltered;
});

export const searchTextSelector = createSelector([orderHistorySelector], (orderHistory): string => {
  return orderHistory.searchText;
});

export const showFilterDialogSelector = createSelector([orderHistorySelector], (orderHistory): boolean => {
  return orderHistory.filters.showFilterDialog;
});

export const getDefaultStartDate = (): Date => {
  return new Date(new Date().getFullYear(), new Date().getMonth() - 3, new Date().getDate());
};
