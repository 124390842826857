import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleImpersonationSelectDialogAction } from '../../app/Customer/customer.actions';
import { customerInfoSelector, isSuperUserSelector } from '../../app/Customer/customer.selectors';
import Profile from '../../core/auth/components/Profile';
import { IState } from '../../state/store.state';

const HeaderProfile = () => {
  const dispatch = useDispatch();
  const headerProfileClasses = useStyles();
  const isSuperUser = useSelector((state: IState) => isSuperUserSelector(state));
  const customerInfo = useSelector((state: IState) => customerInfoSelector(state));
  const isDesktop = useMediaQuery('(min-width:960px)');

  const handleImpersonationClick = () => {
    if (isSuperUser) {
      dispatch(toggleImpersonationSelectDialogAction());
    }
  };

  return (
    <div>
      <div className={headerProfileClasses.rightContainer}>
        {isDesktop && (
          <Box marginRight="40px">
            <Typography onClick={handleImpersonationClick} className={headerProfileClasses.companyText}>
              {isSuperUser ? 'MEC Super User' : customerInfo.company}
            </Typography>
          </Box>
        )}
        <Box marginRight="20px" className={headerProfileClasses.profile}>
          <Profile />
        </Box>
      </div>
    </div>
  );
};

export default HeaderProfile;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    profile: {
      cursor: 'pointer',
      color: theme.palette.common.white
    },
    companyText: {
      fontWeight: 'bolder',
      color: theme.palette.common.white
    },
    rightContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end'
    }
  })
);
