import * as filterActionInterface from '../Filter/filter.actions.interfaces';
import { FILTER_RESET, FILTER_SELECTION_SUCCESS } from '../Filter/filter.actions.constants';

import * as productActionInterface from './product.actions.interfaces';
import {
  PRODUCT_DEFAULT_SORT_ORDER,
  PRODUCT_LOAD_ERROR,
  PRODUCT_LOAD_PENDING,
  PRODUCT_LOAD_SUCCESS,
  PRODUCT_SELECT,
  SET_FILTER_PAGE,
  SET_ROWS_PER_PAGE,
  SET_SORT
} from './product.actions.constants';
import { IProductState } from './product.state.interfaces';

export const initialState: IProductState = {
  isPending: false,
  isDefaultSort: true,
  products: [],
  filteredProducts: [],
  selectedProductIds: [],
  filterResultsPage: 0,
  rowsPerPage: 10,
  sortDirecton: 'asc',
  orderByField: '',
  noResults: false
};

const handleProductLoadPending = (state: IProductState): IProductState => {
  return { ...state, isPending: true };
};

const handleProductLoadSuccess = (
  state: IProductState,
  action: productActionInterface.IProductLoadSuccess
): IProductState => {
  return {
    ...state,
    noResults: action.payload.products.length === 0,
    products: action.payload.products,
    filteredProducts: action.payload.products,
    isPending: false
  };
};

const handleProductLoadError = (state: IProductState): IProductState => {
  return { ...state, isPending: false };
};

const resetProducts = (state: IProductState): IProductState => {
  return { ...state, filteredProducts: state.products };
};

const handleProductSelect = (state: IProductState, action: productActionInterface.IProductSelect): IProductState => {
  return {
    ...state,
    selectedProductIds: action.meta.selectedProductIds
  };
};

const handleDefaultSortOrder = (state: IProductState, action: productActionInterface.IDefaultSort): IProductState => {
  return {
    ...state,
    isDefaultSort: action.meta.isDefaultSort
  };
};

const productReducer = (
  state: IProductState = initialState,
  action:
    | productActionInterface.IProductLoadPending
    | productActionInterface.IProductLoadSuccess
    | productActionInterface.IProductLoadError
    | filterActionInterface.IFilterReset
    | filterActionInterface.IFilterSelectionSuccess
    | productActionInterface.IProductSelect
    | productActionInterface.IGetCertifacteError
    | productActionInterface.IDefaultSort
    | productActionInterface.ISetFilterPage
    | productActionInterface.ISetRowsPerPage
    | productActionInterface.ISetSort
): IProductState => {
  switch (action.type) {
    case PRODUCT_LOAD_PENDING:
      return handleProductLoadPending(state);
    case PRODUCT_LOAD_SUCCESS:
      return handleProductLoadSuccess(state, action);
    case PRODUCT_LOAD_ERROR:
      return handleProductLoadError(state);
    case FILTER_RESET:
      return resetProducts(state);
    case PRODUCT_SELECT:
      return handleProductSelect(state, action);
    case PRODUCT_DEFAULT_SORT_ORDER:
      return handleDefaultSortOrder(state, action);
    case SET_FILTER_PAGE:
      return { ...state, filterResultsPage: action.meta.page };
    case SET_ROWS_PER_PAGE:
      return { ...state, rowsPerPage: action.meta.rowsPerPage };
    case SET_SORT:
      return { ...state, orderByField: action.meta.orderByField, sortDirecton: action.meta.sortDirection };
    case FILTER_SELECTION_SUCCESS:
      return { ...state, isPending: true };
  }

  return state;
};

export default productReducer;
