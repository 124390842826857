import React from 'react';
import { createRoot } from 'react-dom/client';

import { useMediaQuery } from '@mui/material';

import LayoutBase from './layout/components/base-structure/LayoutBase';
import { logger } from './core/diagnostics/logger';
import { setSagas, store } from './state/store';
import { setAppNameAction, setViewModeAction } from './layout/layout.actions';
import { appName, processConfig } from './environment.constants';
import { initAuthAction } from './core/auth/authentication.actions';

logger.info(`Environment is ${processConfig.environment}`, 'index.tsx');

setSagas();

const Index = () => {
  const isDesktop = useMediaQuery('(min-width:960px)');
  store.dispatch(setViewModeAction(isDesktop));

  return <LayoutBase store={store} />;
};

export const init = (appName: string) => {
  store.dispatch(setAppNameAction(appName));
  store.dispatch(initAuthAction());
  logger.info('Starting up Sales Portal');
};

export const renderApp = async () => {
  const container = document.getElementById('root');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(container!); // createRoot(container!) if you use TypeScript
  return root.render(<Index />);
};

renderApp().then(() => {
  init(appName);
});
