import { IState } from '../../state/store.state';
import { getComparator, stableSort } from './Components/ProductTable/TableUtility';

import { IProduct } from './product.state.interfaces';

const getFilteredProducts = (state: IState): IProduct[] => {
  const inCartProductIdList: string[] = state.shopping.cartItems.map((c) => c.productIdentifier);
  return state.products.filteredProducts.filter((f) => !inCartProductIdList.includes(f.productIdentifier));
};

export const isPendingSelector = (state: IState) => state.products.isPending;
export const getFilterResultsPageSelector = (state: IState) => state.products.filterResultsPage;
export const getRowsPerPageSelector = (state: IState) => state.products.rowsPerPage;
export const isDefaultSortSelector = (state: IState) => state.products.isDefaultSort;
export const noResultsSelector = (state: IState) => state.products.noResults;
export const orderByFieldSelector = (state: IState) => state.products.orderByField;
export const sortDirectionSelector = (state: IState) => state.products.sortDirecton;
export const getProductsSelector = (state: IState) => state.products.products;
export const getFilteredProductsSelector = (state: IState): IProduct[] => getFilteredProducts(state);
export const getSelectedProductIdsSelector = (state: IState) => state.products.selectedProductIds;
export const getSelectedProductsSelector = (state: IState) =>
  state.products.products.filter((f) => state.products.selectedProductIds.includes(f.productIdentifier));

export const visibleProductsSelector = (state: IState): IProduct[] => {
  const products: IProduct[] = getFilteredProducts(state);
  const comparator = getComparator(state.products.sortDirecton, state.products.orderByField);
  const sort = (): IProduct[] => stableSort(products, comparator);

  const sorted = (): IProduct[] => {
    return state.products.isDefaultSort ? products : sort();
  };
  return sorted().slice(
    state.products.filterResultsPage * state.products.rowsPerPage,
    state.products.filterResultsPage * state.products.rowsPerPage + state.products.rowsPerPage
  );
};

export const visibleProductsItendifiersWithPriceSelector = (state: IState): string[] => {
  return visibleProductsSelector(state)
    .filter((f) => f.price !== null && f.price > 0)
    .map((m) => m.productIdentifier);
};

export const getProductByProductIdSelector =
  (productIdentifier: string) =>
  (state: IState): IProduct => {
    return state.products.products.filter((p) => p.productIdentifier == productIdentifier)[0];
  };
