import { NumericFormat } from 'react-number-format';
import React from 'react';
import { useDispatch } from 'react-redux';

import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Divider, IconButton, Paper, Typography } from '@mui/material';

import { pricePrecision } from '../../app.constants';
import { shoppingRemoveFromCartPendingAction } from '../shopping.actions';
import { ICartItemModel } from '../shopping.state.interface';

interface IProps {
  readonly cartItem: ICartItemModel;
  readonly isCheckoutPending: boolean;
}

const ShoppingCartItem = (props: IProps) => {
  const dispatch = useDispatch();

  const handleRemoveItem = (productId: string) => () => {
    dispatch(shoppingRemoveFromCartPendingAction(productId));
  };

  const formatProductDescription = (alloy: string, temper: string, gauge: string, width: number, length: number) => {
    const formattedLength = length !== null ? `x ${length}"` : '';
    return `${alloy} ${temper} ${gauge}" x ${width || ''}" ${formattedLength}`;
  };

  return (
    <li key={props.cartItem.productIdentifier}>
      <Paper>
        <Box margin="15px">
          <Box display="flex" flex="1" flexDirection="row">
            <Box flex=".9" alignItems="flex-start">
              <Typography variant="h6">
                {formatProductDescription(
                  props.cartItem.alloy,
                  props.cartItem.temper,
                  props.cartItem.gauge,
                  props.cartItem.width,
                  props.cartItem.length
                )}
              </Typography>
            </Box>
            <Box flex=".1" alignItems="flex-end">
              <IconButton
                key={props.cartItem.productIdentifier + '_delete'}
                edge="start"
                color="secondary"
                disabled={props.isCheckoutPending}
                aria-label="close"
                size={'small'}
                onClick={handleRemoveItem(props.cartItem.productIdentifier)}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
          <Box>
            <Typography variant="body1">
              Weight:{' '}
              <NumericFormat
                value={props.cartItem.sellableWeight}
                displayType={'text'}
                thousandSeparator={true}
                thousandsGroupStyle={'thousand'}
              />{' '}
              lbs
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1">
              Price:{' '}
              <NumericFormat
                value={props.cartItem.price}
                displayType={'text'}
                fixedDecimalScale={true}
                decimalScale={pricePrecision}
                prefix={'$'}
              />{' '}
              per lb / {props.cartItem.warehouseName}
            </Typography>
          </Box>
        </Box>
        <Divider />
      </Paper>
    </li>
  );
};

export default ShoppingCartItem;
