import { IApiRequest, IApiResponse, fetchApi } from '../../core/auth/fetchApiHelper';
import { processConfig } from '../../environment.constants';

import { ICustomerImpersonationInfo, ICustomerInfo } from './customer.state.interface';

export const getCustomerInformation = async (userId: string): Promise<IApiResponse<ICustomerInfo>> => {
  const request: IApiRequest = {
    url: `${processConfig.baseGatewayUrl}/shopping-svc/v1.0/Customer/get-customer-info?userGuid=${userId}`,
    method: 'GET',
    contentType: 'application/json',
    requireAccessToken: true
  };

  const response = await fetchApi(request);
  return <IApiResponse<ICustomerInfo>>response.payload;
};

export const getCustomerInformationSuperUseer = async (): Promise<IApiResponse<ICustomerImpersonationInfo[]>> => {
  const request: IApiRequest = {
    url: `${processConfig.baseGatewayUrl}/shopping-svc/v1.0/Customer/get-customer-list-super-user`,
    method: 'GET',
    contentType: 'application/json',
    requireAccessToken: true
  };

  const response = await fetchApi(request);
  return <IApiResponse<ICustomerImpersonationInfo[]>>response.payload;
};
