export enum googleAnalyticsCommand {
  config = 'config',
  get = 'get',
  set = 'set',
  event = 'event',
  consent = 'consent'
}

export enum CartEventNames {
  addToCart = 'add_to_cart',
  removeFromCart = 'remove_from_cart',
  purchase = 'purchase',
  viewCart = 'view_cart'
}

export enum LogInEventNames {
  login = 'login'
}

export enum ButtonClickEventNames {
  BulletinClick = 'select_content'
}

export enum SearchEventNames {
  search = 'search'
}

export enum googleAnalyticsPropertyNames {
  userProperties = 'user_properties'
}
