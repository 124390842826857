import React from 'react';

import { AppBar, Box } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { ICartItemModel } from '../shopping.state.interface';

import ShoppingCartFooterCheckout from './ShoppingCartFooterCheckout';
import ShoppingCartFooterPoNumber from './ShoppingCartFooterPoNumber';
import ShoppingCartFooterPoUpload from './ShoppingCartFooterPoUpload';
import ShoppingCartFooterTotalPrice from './ShoppingCartFooterTotalPrice';
import ShoppingCartFooterTotalWeight from './ShoppingCartFooterTotalWeight';

interface IProps {
  readonly cartItems: ICartItemModel[];
  readonly isCheckoutPending: boolean;
}

const ShoppingCartFooter = (props: IProps) => {
  const shoppingCartClasses = useStyles();

  return (
    <AppBar position="sticky" color="inherit" className={shoppingCartClasses.footer}>
      <Box flexGrow={1}>
        <ShoppingCartFooterTotalWeight />
        <ShoppingCartFooterTotalPrice />
        {!props.isCheckoutPending && (
          <>
            <ShoppingCartFooterPoUpload orderId={-1} />
            <ShoppingCartFooterPoNumber orderId={-1} />
          </>
        )}
        <ShoppingCartFooterCheckout cartItems={props.cartItems} isCheckoutPending={props.isCheckoutPending} />
      </Box>
    </AppBar>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    footer: {
      top: 'auto',
      bottom: 0
    }
  })
);

export default ShoppingCartFooter;
