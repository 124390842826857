import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { useIsAuthenticated } from '@azure/msal-react';

import Suspense from '../Suspense';

import paths from './paths';

const OrderHistory = React.lazy(() => import('../../../app/OrderHistory/Components/OrderHistory'));
const Product = React.lazy(() => import('../../../app/Product/Components/Product'));

const ContactUs = React.lazy(() => import('../../../app/Main/ContactUs'));
const Landing = React.lazy(() => import('../../../app/Main/Landing'));
const Legal = React.lazy(() => import('../../../app/Main/Legal'));
const Register = React.lazy(() => import('../../../core/auth/components/RegistrationRedirect'));

const routes = () => {
  const isAuthenticated = useIsAuthenticated();

  const authenticatedRoutes = isAuthenticated
    ? [
        <Route key="Product" path={paths.product} element={<Product />} />,
        <Route key="OrderHistory" path={paths.orderHistory} element={<OrderHistory />} />
      ]
    : [];

  const router = (
    <React.Suspense fallback={<Suspense />}>
      <Routes>
        <Route key="Landing" path={paths.landing} element={<Landing />} />
        <Route key="ContactUs" path={paths.contactUs} element={<ContactUs />} />
        <Route key="Legal" path={paths.legal} element={<Legal />} />
        <Route key="Register" path={paths.register} element={<Register />} />
        {authenticatedRoutes}
      </Routes>
    </React.Suspense>
  );

  return router;
};

export default routes;
