import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../../../state/store.state';

import CustomerPoUpload from '../../../CustomerOrderInfo/Components/CustomerPoUpload';
import { fileCapturePendingAction, setCusomerPoFileAction } from '../../../CustomerOrderInfo/customerPo.actions';
import { customerPoFileCapturePendingSelector } from '../../../CustomerOrderInfo/customerPo.selectors';

interface IProps {
  orderId: number;
  fileName: string | undefined;
  orderDocumentLocation?: string;
}

const ShoppingCartCustomerPoUpload = (props: IProps) => {
  const dispatch = useDispatch();

  const isFileCapturePending = useSelector((state: IState) => customerPoFileCapturePendingSelector(state));

  const { fileName, orderId } = props;

  const processFileFunction = (orderId: number, base64: string, fileName: string) => {
    dispatch(setCusomerPoFileAction(orderId, base64, fileName));
  };

  const handleCapture = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(fileCapturePendingAction(event, orderId, processFileFunction));
  };

  if (isFileCapturePending) {
    return (
      <Box sx={{ width: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  return <CustomerPoUpload required={false} orderId={orderId} fileName={fileName} handleCapture={handleCapture} />;
};

export default ShoppingCartCustomerPoUpload;
