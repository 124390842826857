import { SagaIterator } from 'redux-saga';
import { put, takeLatest } from 'redux-saga/effects';
import { push } from 'redux-first-history';

import { INavigate } from './layout.actions.interfaces';
import { NAVIGATE } from './layout.constants';

function* navigate(action: INavigate) {
  yield put(push(action.meta.url));
}

function* appSaga(): SagaIterator {
  yield takeLatest(NAVIGATE, navigate);
}

export default appSaga;
