import { IApiRequest, IApiResponse, fetchApi } from '../../core/auth/fetchApiHelper';
import { processConfig } from '../../environment.constants';

import { IBulletinApiResponse } from './bulletin.state.interfaces';

export const getBulletinItems = async (): Promise<IBulletinApiResponse> => {
  const request: IApiRequest = {
    url: `${processConfig.baseGatewayUrl}/shopping-svc/v1.0/Bulletin/get-items`,
    method: 'GET',
    contentType: 'application/json',
    requireAccessToken: true
  };

  const response: IApiResponse<IBulletinApiResponse> = await fetchApi(request);
  return <IBulletinApiResponse>response.payload;
};
