import React from 'react';

import { CircularProgress } from '@mui/material';

const Suspense = () => {
  return (
    <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', height: '100vh', width: '100vw' }}>
      <CircularProgress />
    </div>
  );
};

export default Suspense;
