import { SagaIterator } from 'redux-saga';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';

import { AccountInfo } from '@azure/msal-browser';

import { toggleAlertDialogAction } from '../../app/Notification/notification.actions';
import { IAlertDialogOptions } from '../../app/Notification/notification.state.interface';
import { logger } from '../diagnostics/logger';

import {
  changeRegistrationStatusAction,
  loginSuccessAction,
  registerNewUserSuccessAction
} from './authentication.actions';
import { INIT, LOGIN_SUCCESS, REGISTER_NEW_USER_PENDING } from './authentication.actions.constants';
import { getAccount, logout } from './authentication.msal.api';
import { accountSelector, isRegistrationSelector } from './authentication.selectors';
import { RegistrationStatus } from './authentication.enumeration';
import { MecB2CAccountInfo } from './authentication.state.interfaces';

function* registerNewUser() {
  yield put(registerNewUserSuccessAction());
  yield put(changeRegistrationStatusAction(RegistrationStatus.Completed));
  yield initialize();
}

export function* getUserId() {
  const account: AccountInfo | undefined = yield select(accountSelector);
  if (!account) {
    const userAccount: AccountInfo | undefined = yield call(getAccount);

    if (userAccount) {
      userAccount.localAccountId;
    }
    return undefined;
  }

  return account.localAccountId;
}

export function* getUserAccount() {
  const account: AccountInfo | undefined = yield select(accountSelector);
  if (!account) {
    const userAccount: AccountInfo | undefined = yield call(getAccount);
    return userAccount;
  }

  return account;
}

export function* authWait() {
  const authAccount: AccountInfo | undefined = yield call(getAccount);
  if (!authAccount) {
    logger.info('no auth, wait on take for success');
    yield take(LOGIN_SUCCESS);
    logger.info('auth received');
  }
}

function* initialize() {
  yield authWait();
  const accountInfo: MecB2CAccountInfo | undefined = yield call(getAccount);

  if (accountInfo) {
    yield put(loginSuccessAction(accountInfo));

    const isRegistration: boolean = yield select(isRegistrationSelector);

    if (isRegistration) {
      const dialogOptions: IAlertDialogOptions = {
        onClose: () => logout(),
        title: 'Thank you for registering with us!',
        message:
          'Your registration for access has been received. An MEC Sales Representative will contact you within one business day to complete your registration.'
      };
      yield put(toggleAlertDialogAction(true, dialogOptions));
    }
  }
}

function* authSaga(): SagaIterator {
  yield takeLatest(REGISTER_NEW_USER_PENDING, registerNewUser);
  yield takeLatest(INIT, initialize);
}

export default authSaga;
