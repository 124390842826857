import {
  GET_CUSTOMER_INFO_ERROR,
  GET_CUSTOMER_INFO_PENDING,
  GET_CUSTOMER_INFO_SUCCESS,
  GET_CUSTOMER_SUPER_USER_INFO_ERROR,
  GET_CUSTOMER_SUPER_USER_INFO_PENDING,
  GET_CUSTOMER_SUPER_USER_INFO_SUCCESS,
  IMPERSONATE_ERROR,
  IMPERSONATE_PENDING,
  IMPERSONATE_SUCCESS,
  SET_CUSTOMER_FOR_SUPER_USER,
  TOGGLE_IMPERSONATION_SELECT_DIALOG
} from './customer.actions.constants';
import {
  IGetCustomerInfoError,
  IGetCustomerInfoPending,
  IGetCustomerInfoSuccess,
  IGetCustomerSuperUserInfoError,
  IGetCustomerSuperUserInfoPending,
  IGetCustomerSuperUserInfoSuccess,
  IImpersonateError,
  IImpersonatePending,
  IImpersonateSuccess,
  IToggleImpersonationSelectDialog
} from './customer.actions.interfaces';
import { ICustomerImpersonationInfo, ICustomerInfo } from './customer.state.interface';

export const getCustomerInfoPendingAction = (): IGetCustomerInfoPending => ({
  type: GET_CUSTOMER_INFO_PENDING
});

export const getCustomerInfoSuccessAction = (payload: ICustomerInfo): IGetCustomerInfoSuccess => ({
  type: GET_CUSTOMER_INFO_SUCCESS,
  payload
});

export const getCustomerInfoErrorAction = (): IGetCustomerInfoError => ({
  type: GET_CUSTOMER_INFO_ERROR
});

export const getCustomerSuperUserInfoPendingAction = (): IGetCustomerSuperUserInfoPending => ({
  type: GET_CUSTOMER_SUPER_USER_INFO_PENDING
});

export const getCustomerSuperUserInfoSuccessAction = (
  payload: ICustomerImpersonationInfo[]
): IGetCustomerSuperUserInfoSuccess => ({
  type: GET_CUSTOMER_SUPER_USER_INFO_SUCCESS,
  payload
});

export const getCustomerSuperUserInfoErrorAction = (): IGetCustomerSuperUserInfoError => ({
  type: GET_CUSTOMER_SUPER_USER_INFO_ERROR
});

export const setCustomerInfoForSuperUserAction = (
  customerInfoForSuperUser: ICustomerImpersonationInfo | undefined
) => ({
  type: SET_CUSTOMER_FOR_SUPER_USER,
  meta: { customerInfoForSuperUser }
});

export const toggleImpersonationSelectDialogAction = (): IToggleImpersonationSelectDialog => ({
  type: TOGGLE_IMPERSONATION_SELECT_DIALOG
});

export const impersonatePendingAction = (): IImpersonatePending => ({
  type: IMPERSONATE_PENDING
});

export const impersonateSuccessAction = (): IImpersonateSuccess => ({
  type: IMPERSONATE_SUCCESS
});

export const impersonateErrorAction = (): IImpersonateError => ({
  type: IMPERSONATE_ERROR
});
