import React from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Button, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  input: {
    display: 'none'
  },
  uploadButton: {
    color: 'red'
  },
  checkBox: {
    color: 'green'
  }
}));

interface IProps {
  readonly inputControlId: string;
  readonly orderId: number;
  readonly fileName: string | null | undefined;
  readonly required: boolean;
}

const CustomerPoUploadAttach = (props: IProps) => {
  const classes = useStyles();

  return (
    <label htmlFor={props.inputControlId}>
      <Tooltip title={props.fileName || 'Please upload your purchase order document'}>
        <Button
          id={'contained-button-button' + props.orderId}
          variant="text"
          className={props.required ? classes.uploadButton : ''}
          component="span"
          startIcon={<AttachFileIcon />}
        >
          ATTACH PO
        </Button>
      </Tooltip>
    </label>
  );
};

export default CustomerPoUploadAttach;
