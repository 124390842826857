import { SagaIterator } from 'redux-saga';
import { StrictEffect, call, put, select, takeLatest } from 'redux-saga/effects';

import { authWait, getUserId } from '../../core/auth/authentication.saga';
import { logger } from '../../core/diagnostics/logger';
import { notifyErrorAction } from '../Notification/notification.actions';

import { productSelectAction } from '../Product/product.actions';
import { getFilterCriteriaSelector } from './filters.selectors';
import { FILTER_DELETE_PENDING, FILTER_SELECTION_PENDING } from './filter.actions.constants';
import { filterSelectionSuccessAction } from './filter.actions';

const fileName = 'filter.saga.ts';

function* handleFilterSelected(): Generator<StrictEffect, any, any> {
  try {
    yield call(authWait);
    const filterCriteria = yield select(getFilterCriteriaSelector);
    if (filterCriteria.userId === '') {
      filterCriteria.userId = yield call(getUserId);
    }
    yield put(filterSelectionSuccessAction(filterCriteria));
    yield put(productSelectAction([]));
  } catch (e) {
    logger.error('handleFilterSelected', e as Error, fileName);
    notifyErrorAction(e as Error, 'We were not able to apply the selected filter');
  }
}

function* filterSaga(): SagaIterator {
  yield takeLatest(FILTER_DELETE_PENDING, handleFilterSelected);
  yield takeLatest(FILTER_SELECTION_PENDING, handleFilterSelected);
}

export default filterSaga;
