import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';

import { select } from '@redux-saga/core/effects';

import { getUserId } from '../../core/auth/authentication.saga';
import { FILTER_SELECTION_PENDING } from '../Filter/filter.actions.constants';
import { IFilterSelectionPending } from '../Filter/filter.actions.interfaces';

function* processFilterSelectionPending(action: IFilterSelectionPending) {
  const userId: string = yield select(getUserId);
}

function* analyticsSaga(): SagaIterator {
  yield takeLatest(FILTER_SELECTION_PENDING, processFilterSelectionPending);
}

export default analyticsSaga;
