import React from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';

import { MsalProvider } from '@azure/msal-react';
import { ThemeProvider } from '@mui/material';

import { HistoryRouter as Router } from 'redux-first-history/rr6';
import AppErrorBoundry from '../../../core/error-boundry/AppErrorBoundry';
import { publicClientApplication } from '../../../core/auth/authentication.config';

import { salesPortalTheme } from '../../themes/salesPortalTheme';

import { reduxHistory } from '../../../state/store';
import LoginOrRegister from '../../../core/auth/components/LoginOrRegister';
import LayoutApp from './LayoutApp';

interface IProps {
  readonly store: Store;
}

const LayoutBase = (props: IProps) => {
  return (
    <React.StrictMode>
      <Provider store={props.store}>
        <ThemeProvider theme={salesPortalTheme}>
          <MsalProvider instance={publicClientApplication}>
            <AppErrorBoundry>
              <Router history={reduxHistory}>
                <LoginOrRegister>
                  <LayoutApp />
                </LoginOrRegister>
              </Router>
            </AppErrorBoundry>
          </MsalProvider>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
};

export default LayoutBase;
