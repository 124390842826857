import {
  GET_CUSTOMER_INFO_SUCCESS,
  GET_CUSTOMER_SUPER_USER_INFO_SUCCESS,
  IMPERSONATE_ERROR,
  IMPERSONATE_PENDING,
  IMPERSONATE_SUCCESS,
  SET_CUSTOMER_FOR_SUPER_USER,
  TOGGLE_IMPERSONATION_SELECT_DIALOG
} from './customer.actions.constants';
import {
  IGetCustomerInfoSuccess,
  IGetCustomerSuperUserInfoSuccess,
  IImpersonateError,
  IImpersonatePending,
  IImpersonateSuccess,
  ISetCustomerForSuperUser,
  IToggleImpersonationSelectDialog
} from './customer.actions.interfaces';
import { ICustomerState } from './customer.state.interface';

export const initialState: ICustomerState = {
  customerInfo: {
    displayName: '',
    userId: '',
    company: ''
  },
  customerImpersonationInfoList: [],
  impersonation: undefined,
  showImpersonationSelectDialog: false,
  impersonationPending: false
};

const handleGetCustomerInfoSuccess = (state: ICustomerState, action: IGetCustomerInfoSuccess): ICustomerState => {
  return { ...state, customerInfo: action.payload };
};

const handleGetCustomerSuperUserInfoSuccess = (
  state: ICustomerState,
  action: IGetCustomerSuperUserInfoSuccess
): ICustomerState => {
  return { ...state, customerImpersonationInfoList: action.payload };
};

const customerReducer = (
  state: ICustomerState = initialState,
  action:
    | IGetCustomerInfoSuccess
    | IGetCustomerSuperUserInfoSuccess
    | ISetCustomerForSuperUser
    | IToggleImpersonationSelectDialog
    | IImpersonateError
    | IImpersonateSuccess
    | IImpersonatePending
): ICustomerState => {
  switch (action.type) {
    case GET_CUSTOMER_INFO_SUCCESS:
      return handleGetCustomerInfoSuccess(state, action);
    case GET_CUSTOMER_SUPER_USER_INFO_SUCCESS:
      return handleGetCustomerSuperUserInfoSuccess(state, action);
    case SET_CUSTOMER_FOR_SUPER_USER:
      return { ...state, impersonation: action.meta.customerInfoForSuperUser };
    case TOGGLE_IMPERSONATION_SELECT_DIALOG:
      return { ...state, showImpersonationSelectDialog: !state.showImpersonationSelectDialog };
    case IMPERSONATE_PENDING:
      return { ...state, impersonationPending: true };
    case IMPERSONATE_SUCCESS:
      return { ...state, impersonationPending: false };
    case IMPERSONATE_ERROR:
      return { ...state, impersonationPending: false };
    default:
      return state;
  }
};

export default customerReducer;
