import { createTheme } from '@mui/material/styles';

export const salesPortalTheme = createTheme({
  typography: {
    fontFamily: 'Open Sans, Helvetica, Arial, sans-serif'
  },
  palette: {
    primary: {
      main: '#1f4a97',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#a66d4c',
      contrastText: '#000000'
    },
    error: {
      main: '#971f49'
    },
    warning: {
      main: '#ff6c00'
    },
    info: {
      main: '#5975c8'
    },
    success: {
      main: '#5baf00'
    },
    text: {
      primary: '#000000',
      secondary: '#666666',
      disabled: 'rgba(0, 0, 0, 0.38)'
    }
  }
});
