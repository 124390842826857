import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Divider, Drawer, List, ThemeProvider, Typography, useTheme } from '@mui/material';

import {
  cartItemsByWarehouseSelector,
  cartItemsSelector,
  isCartOpenSelector,
  isCheckoutPendingSelector
} from '../shopping.selectors';
import { IState } from '../../../state/store.state';
import { toggleShoppingCartAction } from '../shopping.actions';
import { ICartItemModel } from '../shopping.state.interface';
import { isDesktopModeSelector } from '../../../layout/layout.selectors';

import ShoppingCartFooter from './ShoppingCartFooter';
import ShoppingCartHeader from './ShoppingCartHeader';
import ShoppingCartItem from './ShoppingCartItem';

const ShoppingCart = () => {
  const theme = useTheme();

  const dispatch = useDispatch();

  const isCartOpen = useSelector((state: IState) => isCartOpenSelector(state));
  const isDesktop = useSelector((state: IState) => isDesktopModeSelector(state));
  const cartItems = useSelector((state: IState) => cartItemsSelector(state));
  const isCheckoutPending = useSelector((state: IState) => isCheckoutPendingSelector(state));
  const cartItemsByWarehouse = useSelector((state: IState) => cartItemsByWarehouseSelector(state));
  const warehouses = Object.keys(cartItemsByWarehouse);

  const handleClose = () => {
    dispatch(toggleShoppingCartAction());
  };

  const cartItemRender = () => {
    return warehouses
      .map((warehouse) => {
        return [
          <li key={warehouse}>
            <Box paddingTop={2}>
              <Typography color="secondary">{warehouse}</Typography>
            </Box>
          </li>
        ].concat(
          cartItemsByWarehouse[warehouse].map((cartItem: ICartItemModel) => {
            return (
              <ShoppingCartItem
                key={cartItem.productIdentifier}
                cartItem={cartItem}
                isCheckoutPending={isCheckoutPending}
              />
            );
          })
        );
      })
      .reduce((a, b) => a.concat(b), []); // flatten
  };

  return (
    <ThemeProvider theme={theme}>
      <Drawer anchor="right" open={isCartOpen} onClose={handleClose}>
        <ShoppingCartHeader cartItems={cartItems} handleClose={handleClose} isCheckoutPending={isCheckoutPending} />
        <Divider />
        <Box display="flex" flex="1" flexGrow={1} flexDirection="column" width={isDesktop ? '400px' : '100vw'}>
          <Box margin="15px" flexGrow={1}>
            <List style={{ maxHeight: '100%', overflow: 'auto', fontFamily: 'Helvetica, Arial, sans-serif' }}>
              {warehouses.length > 0 ? cartItemRender() : 'Your cart is empty'}
            </List>
          </Box>
        </Box>
        <Divider />
        <ShoppingCartFooter cartItems={cartItems} isCheckoutPending={isCheckoutPending} />
      </Drawer>
    </ThemeProvider>
  );
};

export default ShoppingCart;
