import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { InteractionStatus } from '@azure/msal-browser';
import { useAccount, useMsal } from '@azure/msal-react';
import { IState } from '../../../state/store.state';
import { processConfig } from '../../../environment.constants';
import paths from '../../../layout/components/router/paths';
import { registrationStatusSelector } from '../authentication.selectors';
import { RegistrationStatus } from '../authentication.enumeration';

interface IProps {
  readonly children: React.ReactNode;
}

const LoginOrRegister = (props: IProps) => {
  const { inProgress, accounts, instance } = useMsal();
  const registrationStatus = useSelector((state: IState) => registrationStatusSelector(state));

  const account = useAccount(accounts[0] || {});
  const msalInProgress = inProgress !== InteractionStatus.None;
  const isRegistrationPath = location.pathname === paths.register;

  const isRegistrationFlow = registrationStatus === RegistrationStatus.Pending;
  const isLoginFlow = !account && !isRegistrationFlow && !msalInProgress && !isRegistrationPath;

  const register = () =>
    instance.loginRedirect({
      authority: processConfig.auth.msal.authorityRegister,
      scopes: []
    });

  const login = () =>
    instance.loginRedirect({
      scopes: [processConfig.auth.msal.loginScope || '']
    });

  useEffect(() => {
    isRegistrationFlow && register();
    isLoginFlow && login();
  });

  return <>{props.children}</>;
};

export default LoginOrRegister;
