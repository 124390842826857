import React from 'react';
import { NavLink } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';

import { processConfig } from '../../environment.constants';

const footer = () => {
  const footerClasses = useStyles();

  const openClaimPdf = () => {
    window.open(processConfig.claimsPolicyUrl, '_blank');
  };

  return (
    <Box className={footerClasses.footerContent}>
      <Box className={footerClasses.companyContent}>
        <Box className={footerClasses.companyLinks}>
          <NavLink to="/contact-us" className={footerClasses.link}>
            <Typography variant="caption">Contact Us</Typography>
          </NavLink>
        </Box>
      </Box>
      <Box className={footerClasses.legalContent}>
        <Box className={footerClasses.legalLinks}>
          <NavLink to="/legal" className={footerClasses.link}>
            <Typography variant="caption">Legal Terms Of Use / Privacy Policy</Typography>
          </NavLink>
          <Typography
            variant="caption"
            onClick={() => openClaimPdf()}
            className={[footerClasses.link, footerClasses.claimsLink].join(' ')}
          >
            MEC Claims Policy
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default footer;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerContent: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      backgroundColor: '#333333',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column'
      },
      borderRadius: '0px'
    },
    companyContent: {
      display: 'flex',
      flex: 0.5,
      margin: '5px',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        dissplay: 'flex',
        flex: 0.2
      }
    },
    companyLinks: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    legalContent: {
      display: 'flex',
      flex: 0.5,
      margin: '5px',
      justifyContent: 'center'
    },
    legalLinks: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    claimsLink: {
      cursor: 'pointer'
    },
    link: {
      color: theme.palette.common.white,
      textDecoration: 'none'
    }
  })
);
