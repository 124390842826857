import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, CircularProgress, Grid, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { shoppingPurchaseRequestPendingAction } from '../shopping.actions';
import { hasMultipleWarehousesSelector, isCheckoutDisabledSelector } from '../shopping.selectors';
import { IState } from '../../../state/store.state';
import { ICartItemModel } from '../shopping.state.interface';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center'
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative'
    },
    buttonProgress: {
      color: theme.palette.primary.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    }
  })
);
interface IProps {
  readonly cartItems: ICartItemModel[];
  readonly isCheckoutPending: boolean;
}

const ShoppingCartFooterCheckout = (props: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isCheckoutDisabled = useSelector((state: IState) => isCheckoutDisabledSelector(state));
  const hasMultipleWarehouses = useSelector((state: IState) => hasMultipleWarehousesSelector(state));

  const handlePurchaseRequest = () => {
    dispatch(shoppingPurchaseRequestPendingAction());
  };
  console.log(hasMultipleWarehouses, 'bad');
  return (
    <Box margin="15px">
      {hasMultipleWarehouses && <Typography color="primary">*Order contains items from multiple locations</Typography>}
      <Grid container={true} item={true} xs={12} justifyContent="center" alignItems="center">
        <div className={classes.wrapper}>
          <Button variant="contained" color="secondary" onClick={handlePurchaseRequest} disabled={isCheckoutDisabled}>
            Send Purchase Request
          </Button>
          {props.isCheckoutPending && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </Grid>
    </Box>
  );
};

export default ShoppingCartFooterCheckout;
