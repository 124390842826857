import React from 'react';
import { useSelector } from 'react-redux';

import { Box, Grid, Typography } from '@mui/material';

import { IState } from '../../../state/store.state';
import { customerPoFileSelector } from '../../CustomerOrderInfo/customerPo.selectors';

import ShoppingCartCustomerPoUpload from './CustomerPo/ShoppingCartCustomerPoUpload';
interface IProps {
  readonly orderId: number;
}

export const ShoppingCartPurchaseUpload = (props: IProps) => {
  const customerPoFile = useSelector((state: IState) => customerPoFileSelector(state));

  return (
    <Box margin="15px">
      <Grid container={true} direction="row">
        <Grid container={true} item={true} xs={6}>
          <Box>
            <Typography color="textPrimary" variant="subtitle2">
              PO Document:
            </Typography>
          </Box>
        </Grid>
        <Grid container={true} item={true} xs={6} justifyContent="flex-end" alignItems="flex-end">
          <Box>
            <ShoppingCartCustomerPoUpload orderId={props.orderId} fileName={customerPoFile.customerPoDocumentName} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShoppingCartPurchaseUpload;
