import { ICartItemModel } from './shopping.state.interface';
import {
  IShoppingAddPending,
  IShoppingAddSuccess,
  IShoppingCartExpirationMonitorStart,
  IShoppingCartMonitorStart,
  IShoppingGetCartError,
  IShoppingGetCartPending,
  IShoppingGetCartSuccess,
  IShoppingPurchaseRequestError,
  IShoppingPurchaseRequestPending,
  IShoppingPurchaseRequestSuccess,
  IShoppingRemoveAllError,
  IShoppingRemoveAllPending,
  IShoppingRemoveAllSuccess,
  IShoppingRemoveError,
  IShoppingRemovePending,
  IShoppingRemoveSuccess,
  IShoppingSetExpiredCart,
  IShoppingStartup,
  IToggleShoppingCart,
  IUpdateCartItemsAtServer,
  IViewShoppingCart
} from './shopping.actions.interfaces';
import {
  SHOPPING_ADD_TO_CART_PENDING,
  SHOPPING_ADD_TO_CART_SUCCESS,
  SHOPPING_CART_EXPIRATION_MONITOR_START,
  SHOPPING_CART_MONITOR_START,
  SHOPPING_CART_TOGGLE,
  SHOPPING_GET_CART_ERROR,
  SHOPPING_GET_CART_PENDING,
  SHOPPING_GET_CART_SUCCESS,
  SHOPPING_PURCHASE_REQUEST_ERROR,
  SHOPPING_PURCHASE_REQUEST_PENDING,
  SHOPPING_PURCHASE_REQUEST_SUCCESS,
  SHOPPING_REMOVE_ALL_FROM_CART_ERROR,
  SHOPPING_REMOVE_ALL_FROM_CART_PENDING,
  SHOPPING_REMOVE_ALL_FROM_CART_SUCCESS,
  SHOPPING_REMOVE_FROM_CART_ERROR,
  SHOPPING_REMOVE_FROM_CART_PENDING,
  SHOPPING_REMOVE_FROM_CART_SUCCESS,
  SHOPPING_STARTUP,
  SHOPPING_TOGGLE_EXPIRED_CART,
  SHOPPING_UPDATE_CART_ITEMS_AT_SERVER,
  SHOPPING_VIEW_CART_PENDING
} from './shopping.actions.constants';

export const shoppingStartupAction = (): IShoppingStartup => ({
  type: SHOPPING_STARTUP
});

export const shoppingAddToCartPendingAction = (productList: ICartItemModel[]): IShoppingAddPending => ({
  type: SHOPPING_ADD_TO_CART_PENDING,
  meta: { productList }
});

export const shoppingAddToCartSucceessAction = (productList: ICartItemModel[]): IShoppingAddSuccess => ({
  type: SHOPPING_ADD_TO_CART_SUCCESS,
  meta: { productList }
});

export const shoppingRemoveFromCartPendingAction = (productIdentifier: string): IShoppingRemovePending => ({
  type: SHOPPING_REMOVE_FROM_CART_PENDING,
  meta: { productIdentifier }
});

export const shoppingRemoveFromCartSuccessAction = (productIdentifier: string): IShoppingRemoveSuccess => ({
  type: SHOPPING_REMOVE_FROM_CART_SUCCESS,
  payload: { productIdentifier }
});

export const shoppingRemoveFromCartErrorAction = (): IShoppingRemoveError => ({
  type: SHOPPING_REMOVE_FROM_CART_ERROR
});

export const shoppingPurchaseRequestPendingAction = (): IShoppingPurchaseRequestPending => ({
  type: SHOPPING_PURCHASE_REQUEST_PENDING
});

export const shoppingPurchaseRequestSuccessAction = (): IShoppingPurchaseRequestSuccess => ({
  type: SHOPPING_PURCHASE_REQUEST_SUCCESS
});

export const shoppingPurchaseRequestErrorAction = (): IShoppingPurchaseRequestError => ({
  type: SHOPPING_PURCHASE_REQUEST_ERROR
});

export const shoppingRemoveAllFromCartPendingAction = (cartItems: ICartItemModel[]): IShoppingRemoveAllPending => ({
  type: SHOPPING_REMOVE_ALL_FROM_CART_PENDING,
  meta: { cartItems }
});

export const shoppingRemoveAllFromCartSuccessAction = (): IShoppingRemoveAllSuccess => ({
  type: SHOPPING_REMOVE_ALL_FROM_CART_SUCCESS
});

export const shoppingRemoveAllFromCartErrorAction = (): IShoppingRemoveAllError => ({
  type: SHOPPING_REMOVE_ALL_FROM_CART_ERROR
});

export const shoppingToggleExpiredCartAction = (isExpired: boolean): IShoppingSetExpiredCart => ({
  type: SHOPPING_TOGGLE_EXPIRED_CART,
  meta: { isExpired }
});

export const shoppingCartMonitorStartAction = (): IShoppingCartMonitorStart => ({
  type: SHOPPING_CART_MONITOR_START
});

export const shoppingCartExpirationMonitorStartAction = (): IShoppingCartExpirationMonitorStart => ({
  type: SHOPPING_CART_EXPIRATION_MONITOR_START
});

export const shoppingGetCartPendingAction = (): IShoppingGetCartPending => ({
  type: SHOPPING_GET_CART_PENDING
});

export const shoppingGetCartSuccessAction = (cartItems: ICartItemModel[]): IShoppingGetCartSuccess => ({
  type: SHOPPING_GET_CART_SUCCESS,
  payload: { cartItems }
});

export const shoppingGetCartErrorAction = (): IShoppingGetCartError => ({
  type: SHOPPING_GET_CART_ERROR
});

export const updateCartItemsAtServerAction = (productIdListServer: string[] | undefined): IUpdateCartItemsAtServer => ({
  type: SHOPPING_UPDATE_CART_ITEMS_AT_SERVER,
  meta: { productIdListServer }
});

export const toggleShoppingCartAction = (): IToggleShoppingCart => ({
  type: SHOPPING_CART_TOGGLE
});

export const viewShoppingCartPendingAction = (): IViewShoppingCart => ({
  type: SHOPPING_VIEW_CART_PENDING
});
