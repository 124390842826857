import React from 'react';

import { Box, Grid, Typography } from '@mui/material';

import ShoppingCartCustomerPoNumber from './CustomerPo/ShoppingCartCustomerPoNumber';
interface IProps {
  readonly orderId: number;
}

const ShoppingCartFooterPoNumber = (props: IProps) => {
  return (
    <Box margin="15px">
      <Grid container={true} direction="row">
        <Grid container={true} item={true} xs={6}>
          <Box>
            <Typography color="textPrimary" variant="subtitle2">
              PO Ref Number:
            </Typography>
          </Box>
        </Grid>
        <Grid container={true} item={true} xs={6} justifyContent="flex-end" alignItems="flex-end">
          <Box>
            <ShoppingCartCustomerPoNumber orderId={props.orderId} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShoppingCartFooterPoNumber;
