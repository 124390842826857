import {
  IFileCaptureError,
  IFileCapturePending,
  IFileCaptureSuccess,
  IResetCustomerPoFile,
  IResetCustomerPoNumber,
  ISetCustomerPoFile,
  ISetCustomerPoNumber
} from './customerPo.action.interfaces';
import {
  FILE_CAPTURE_ERROR,
  FILE_CAPTURE_PENDING,
  FILE_CAPTURE_SUCCESS,
  RESET_CUSTOMER_PURCHASE_ORDER_FILE,
  RESET_CUSTOMER_PURCHASE_ORDER_NUMBER,
  SET_CUSTOMER_PURCHASE_ORDER_FILE,
  SET_CUSTOMER_PURCHASE_ORDER_NUMBER
} from './customerPo.actions.constants';
import { ICustomerPoState } from './customerPo.state.interface';

export const initialState: ICustomerPoState = {
  customerPoFile: {
    customerPoDocumentBase64: undefined,
    customerPoDocumentName: undefined,
    orderId: undefined,
    isFileCapturePending: false
  },
  customerPoNumber: {
    orderId: undefined,
    customerPoNumber: undefined
  }
};

const handleSetCustomerPurchaseFile = (state: ICustomerPoState, action: ISetCustomerPoFile) => {
  return {
    ...state,
    customerPoFile: {
      orderId: action.meta.orderId,
      customerPoDocumentName: action.meta.fileName,
      customerPoDocumentBase64: action.meta.file,
      isFileCapturePending: false
    }
  };
};

const handleSetCustomerPurchaseFileCaptureState = (
  state: ICustomerPoState,
  action: IFileCapturePending | IFileCaptureError | IFileCaptureSuccess
) => {
  const customerPoFile = { ...state.customerPoFile, isFileCapturePending: action.type === FILE_CAPTURE_PENDING };
  return {
    ...state,
    customerPoFile
  };
};

const handleSetCustomerPurchaseNumber = (state: ICustomerPoState, action: ISetCustomerPoNumber) => {
  return {
    ...state,
    customerPoNumber: {
      orderId: action.meta.orderId,
      customerPoNumber: action.meta.customerPoNumber
    }
  };
};

const handleResetCustomerPoFile = (state: ICustomerPoState, action: IResetCustomerPoFile) => {
  const customerPoFile = {
    ...state.customerPoFile,
    orderId: action.meta.orderId
  };
  return { ...initialState, customerPoFile };
};

const handleResetCustomerPoNumber = (state: ICustomerPoState, action: IResetCustomerPoNumber) => {
  const customerPoNumber = {
    ...state.customerPoNumber,
    orderId: action.meta.orderId
  };
  return { ...initialState, customerPoNumber };
};

export const customerOrderInfoReducer = (
  state: ICustomerPoState = initialState,
  action:
    | ISetCustomerPoFile
    | ISetCustomerPoNumber
    | IResetCustomerPoFile
    | IResetCustomerPoNumber
    | IFileCapturePending
    | IFileCaptureError
    | IFileCaptureSuccess
): ICustomerPoState => {
  switch (action.type) {
    case SET_CUSTOMER_PURCHASE_ORDER_FILE:
      return handleSetCustomerPurchaseFile(state, action);
    case SET_CUSTOMER_PURCHASE_ORDER_NUMBER:
      return handleSetCustomerPurchaseNumber(state, action);
    case RESET_CUSTOMER_PURCHASE_ORDER_FILE:
      return handleResetCustomerPoFile(state, action);
    case RESET_CUSTOMER_PURCHASE_ORDER_NUMBER:
      return handleResetCustomerPoNumber(state, action);
    case FILE_CAPTURE_PENDING:
      return handleSetCustomerPurchaseFileCaptureState(state, action);
    case FILE_CAPTURE_SUCCESS:
      return handleSetCustomerPurchaseFileCaptureState(state, action);
    case FILE_CAPTURE_ERROR:
      return handleSetCustomerPurchaseFileCaptureState(state, action);
    default:
      return state;
  }
};

export default customerOrderInfoReducer;
