import { logger } from '../diagnostics/logger';
import { getToken } from './authentication.msal.api';

// we have to use our intance of the store that gets created at startup - not the interface from redux.  my bad.
export interface IApiRequest {
  readonly url: string;
  readonly method: string;
  readonly contentType: string;
  readonly body?: any;
  readonly requireAccessToken: boolean;
}

export interface IApiResponse<T> {
  readonly statusCode: number;
  readonly message: string;
  readonly payload?: T;
}

export const fetchApi = async <T extends {}>(request: IApiRequest): Promise<IApiResponse<T>> => {
  const response = await executeFetch(request);
  return await handleResponse(response);
};

const buildHeaders = async (request: IApiRequest): Promise<Headers> => {
  const headers = new Headers({
    'Content-Type': request.contentType
  });

  if (request.requireAccessToken) {
    const token = await getToken();
    headers.append('Authorization', `Bearer ${token}`);
  }

  return headers;
};

const executeFetch = async (request: IApiRequest) => {
  const headers = await buildHeaders(request);

  return await fetch(request.url, {
    headers,
    method: request.method,
    body: JSON.stringify(request.body)
  });
};

const handleResponse = async <T extends {}>(response: Response): Promise<IApiResponse<T>> => {
  try {
    if (response.status <= 400) {
      return await payloadResponse(response);
    }

    return handleFetchException(response);
  } catch (error: unknown) {
    logger.error('Error handling response', error as Error, 'fetchApiHelper.ts');
    return await handleFetchException(response);
  }
};

const handleFetchException = async <T extends {}>(response: Response): Promise<IApiResponse<T>> => {
  const errorText = await response.text();
  throw new Error(`${response.status}/${response.statusText}:" ${errorText}`);
};

const payloadResponse = async <T extends {}>(response: Response): Promise<IApiResponse<T>> => {
  // 202, 204, or errors codes will not have a .text/.json if service does not return a body
  // es6 fetch offers no explcit test. using try/catch instead
  // of relying on status code conditional logic
  const getPayload = async (response: Response) => {
    try {
      return await response.json();
    } catch {
      return null;
    }
  };

  const payloadResponse: IApiResponse<T> = {
    payload: await getPayload(response),
    statusCode: response.status,
    message: response.statusText
  };

  return payloadResponse;
};
