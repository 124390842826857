import { processConfig } from '../../environment.constants';

const trace = (message: string, source = 'general') => {
  if (processConfig.logLevel !== 'trace') {
    return;
  }
  console.trace(`trace/${source}: ${message}`);
};

const info = (message: string, source = 'general') => {
  if (!['trace', 'info'].includes(processConfig.logLevel)) {
    return;
  }
  console.log(`info/${source}: ${message}`);
};

const warn = (message: string, source = 'general') => {
  if (!['trace', 'info', 'warn'].includes(processConfig.logLevel)) {
    return;
  }
  console.warn(`warn/${source}: ${message}`);
};

const error = (message: string, error: Error, source = 'general') => {
  if (!['trace', 'info', 'warn', 'error'].includes(processConfig.logLevel)) {
    // Tractor talk: shouldn't we go ahead and call a core notification svc here?
    return;
  }
  const messageWithError = message + '\n\n' + error.message + '\n\n' + error.stack;
  console.error(`error/${source}: ${messageWithError}`);
};

export const logger = {
  trace,
  info,
  warn,
  error
};
