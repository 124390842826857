import {
  ButtonClickEventNames,
  CartEventNames,
  googleAnalyticsCommand,
  googleAnalyticsPropertyNames,
  LogInEventNames,
  SearchEventNames
} from './analytics.enum';
import {
  IButtonClickEventInfo,
  ICartEventInfo,
  IFilterSearchNoResultEventInfo,
  ILoginEventInfo
} from './anlytics.interfaces';

export const CreateFilterSearchNoResultEvent = (
  eventName: SearchEventNames,
  searchTerm: IFilterSearchNoResultEventInfo
) => {
  window.gtag(googleAnalyticsCommand.event, eventName, searchTerm);
};

export const createCartEvent = (eventName: CartEventNames, addToCartEventInfo: ICartEventInfo) => {
  window.gtag(googleAnalyticsCommand.event, eventName, addToCartEventInfo);
};

export const CreateLoginEvent = (eventName: LogInEventNames, loginEventInfo: ILoginEventInfo) => {
  window.gtag(googleAnalyticsCommand.event, eventName, loginEventInfo);
};

export const CreateButtonClickEvent = (
  eventName: ButtonClickEventNames,
  buttonClickEventInfo: IButtonClickEventInfo
) => {
  window.gtag(googleAnalyticsCommand.event, eventName, buttonClickEventInfo);
};

export const SetLoginUserInfo = (userPropertyName: googleAnalyticsPropertyNames, loginEventInfo: ILoginEventInfo) => {
  window.gtag(googleAnalyticsCommand.set, userPropertyName, loginEventInfo);
};
