import * as productActionInterfaces from './product.actions.interfaces';
import {
  GET_CERTIFICATE_ERROR,
  GET_CERTIFICATE_PENDING,
  GET_CERTIFICATE_SUCCESS,
  PRODUCT_DEFAULT_SORT_ORDER,
  PRODUCT_LOAD_ERROR,
  PRODUCT_LOAD_PENDING,
  PRODUCT_LOAD_SUCCESS,
  PRODUCT_SELECT,
  SET_FILTER_PAGE,
  SET_ROWS_PER_PAGE,
  SET_SORT
} from './product.actions.constants';
import { IProduct, Order } from './product.state.interfaces';

export const productLoadPendingAction = (): productActionInterfaces.IProductLoadPending => ({
  type: PRODUCT_LOAD_PENDING
});

export const productsLoadSuccessAction = (products: IProduct[]): productActionInterfaces.IProductLoadSuccess => ({
  type: PRODUCT_LOAD_SUCCESS,
  payload: { products }
});

export const productsLoadErrorAction = (): productActionInterfaces.IProductLoadError => ({
  type: PRODUCT_LOAD_ERROR
});

export const productSelectAction = (selectedProductIds: string[]): productActionInterfaces.IProductSelect => ({
  type: PRODUCT_SELECT,
  meta: { selectedProductIds }
});

export const getCertificatePendingAction = (url: string): productActionInterfaces.IGetCertifactePending => ({
  type: GET_CERTIFICATE_PENDING,
  meta: { url }
});

export const getCertificateSuccessAction = (file: any): productActionInterfaces.IGetCertifacteSuccess => ({
  type: GET_CERTIFICATE_SUCCESS,
  payload: { file }
});

export const getCertificateErrorAction = (): productActionInterfaces.IGetCertifacteError => ({
  type: GET_CERTIFICATE_ERROR
});

export const isDefaultSortAction = (isDefaultSort: boolean): productActionInterfaces.IDefaultSort => ({
  type: PRODUCT_DEFAULT_SORT_ORDER,
  meta: { isDefaultSort }
});

export const setFilterPageAction = (page: number): productActionInterfaces.ISetFilterPage => ({
  type: SET_FILTER_PAGE,
  meta: { page }
});

export const setRowsPerPageAction = (rowsPerPage: number): productActionInterfaces.ISetRowsPerPage => ({
  type: SET_ROWS_PER_PAGE,
  meta: { rowsPerPage }
});

export const setSortAction = (orderByField: string, sortDirection: Order): productActionInterfaces.ISetSort => ({
  type: SET_SORT,
  meta: { orderByField, sortDirection }
});
