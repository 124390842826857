import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Icon from '@mui/material/Icon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useMsal } from '@azure/msal-react';
import { Box, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import ImpersonationSelectDialog from '../../../app/Customer/Components/ImpersonationSelectDialog';
import { toggleImpersonationSelectDialogAction } from '../../../app/Customer/customer.actions';
import { customerInfoSelector, isSuperUserSelector } from '../../../app/Customer/customer.selectors';
import { IState } from '../../../state/store.state';
import { processConfig } from '../../../environment.constants';
import { userNameSelector } from '../authentication.selectors';

const Profile = () => {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery('(min-width:960px)');
  const customerInfo = useSelector((state: IState) => customerInfoSelector(state));
  const email = useSelector((state: IState) => userNameSelector(state));
  const isSuperUser = useSelector((state: IState) => isSuperUserSelector(state));
  const { instance } = useMsal();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout: any = () => () => {
    instance.logout();
  };

  const handleChangePassword: any = () => () => {
    window.open(processConfig.auth.msal.passwordResetUri, '_self');
  };

  const handleImpersonation: any = () => () => {
    handleMenuClose();
    dispatch(toggleImpersonationSelectDialogAction());
  };

  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'primary-search-account-menu';

  const impersonateMenuItem = isSuperUser ? (
    <MenuItem onClick={handleImpersonation()}>
      <AccountBoxIcon />
      <span style={{ marginLeft: 10 }}>Impersonate</span>
    </MenuItem>
  ) : (
    ''
  );

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted={true}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleChangePassword()}>
        <Icon>edit</Icon>
        <span style={{ marginLeft: 10 }}>Change Password</span>
      </MenuItem>
      <MenuItem onClick={handleLogout()}>
        <Icon>transit_enterexit</Icon>
        <span style={{ marginLeft: 10 }}>Log Out</span>
      </MenuItem>
      {impersonateMenuItem}
    </Menu>
  );

  return (
    <>
      <Tooltip title={email ? email : ''}>
        <Box display="flex" className={classes.profileContainer} onClick={handleMobileMenuOpen}>
          {isDesktop && customerInfo.displayName && (
            <Typography variant="subtitle1">Welcome Back, {customerInfo.displayName}</Typography>
          )}
          <AccountCircleIcon className={classes.icon} color="inherit"></AccountCircleIcon>
        </Box>
      </Tooltip>
      {renderMenu}
      <ImpersonationSelectDialog />
    </>
  );
};

export default Profile;

const useStyles = makeStyles(() => {
  return createStyles({
    profileContainer: {
      flexDirection: 'row',
      alignItems: 'center'
    },
    userContainer: {
      marginRight: 5,
      alignItems: 'center',
      justifyContent: 'center'
    },
    user: {
      alignSelf: 'center',
      cursor: 'pointer',
      justifyContent: 'flex-end'
    },
    icon: {
      marginRight: '50px',
      marginLeft: 10
    }
  });
});
