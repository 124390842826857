import { OrderDocumentType } from '../OrderHistory/order.history.enums';
import * as fileDownloadActionInterfaces from './fileDownload.actions.interfaces';
import { DOWNLOAD_FILE_ERROR, DOWNLOAD_FILE_PENDING, DOWNLOAD_FILE_SUCCESS } from './fileDownload.actions.constants';

export const downloadFilePendingAction = (
  url: string,
  fileName: string,
  orderDocumentType: OrderDocumentType
): fileDownloadActionInterfaces.IDownloadFilePending => ({
  type: DOWNLOAD_FILE_PENDING,
  meta: { url, fileName, orderDocumentType }
});

export const downloadFileSucessAction = (file: any): fileDownloadActionInterfaces.IDownloadFileSuccess => ({
  type: DOWNLOAD_FILE_SUCCESS,
  payload: { file }
});

export const downloadFileErrorAction = (): fileDownloadActionInterfaces.IDownloadFileError => ({
  type: DOWNLOAD_FILE_ERROR
});
