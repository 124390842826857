import { createElement } from 'react';

import { IDarkModeToggle, ISettingsToggle } from './settings.actions.interfaces';
import { DARK_MODE_TOGGLE, SETTINGS_TOGGLE } from './settings.constants';
import { ISettingsState } from './settings.reducer.interfaces';

export const initialState: ISettingsState = {
  showSettings: false,
  isDarkMode: false,
  clientSettings: createElement('div')
};

const toggleSettings = (state: ISettingsState): ISettingsState => {
  return { ...state, showSettings: !state.showSettings };
};

const toggleDarkMode = (state: ISettingsState): ISettingsState => {
  return { ...state, isDarkMode: !state.isDarkMode };
};

type handledActionTypes = ISettingsToggle | IDarkModeToggle;
const settingsReducer = (state: ISettingsState = initialState, action: handledActionTypes) => {
  switch (action.type) {
    case SETTINGS_TOGGLE:
      return toggleSettings(state);
    case DARK_MODE_TOGGLE:
      return toggleDarkMode(state);
  }
  return state;
};

export default settingsReducer;
