import { StrictEffect, call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { authWait, getUserId } from '../../core/auth/authentication.saga';
import { logger } from '../../core/diagnostics/logger';
import { impersonatedUserIdSelector } from '../Customer/customer.selectors';
import {
  customerPoFileSelector,
  customerPoNumberIsErrorSelector,
  customerPoNumberSelector
} from '../CustomerOrderInfo/customerPo.selectors';
import { resetCustomerPoFileAction, resetCustomerPoNumberAction } from '../CustomerOrderInfo/customerPo.actions';
import { notifyErrorAction, notifySuccessAction, toggleAlertDialogAction } from '../Notification/notification.actions';
import { IAlertDialogOptions } from '../Notification/notification.state.interface';
import { productLoadPendingAction } from '../Product/product.actions';

import { ICartItem } from '../Analytics/v2/anlytics.interfaces';
import { createCartEvent } from '../Analytics/v2/analytics.events';
import { CartEventNames } from '../Analytics/v2/analytics.enum';
import { getProductByProductIdSelector } from '../Product/product.selectors';
import { IProduct } from '../Product/product.state.interfaces';
import {
  shoppingAddToCartSucceessAction,
  shoppingCartExpirationMonitorStartAction,
  shoppingCartMonitorStartAction,
  shoppingGetCartPendingAction,
  shoppingGetCartSuccessAction,
  shoppingPurchaseRequestErrorAction,
  shoppingPurchaseRequestSuccessAction,
  shoppingRemoveAllFromCartSuccessAction,
  shoppingRemoveFromCartSuccessAction,
  toggleShoppingCartAction
} from './shopping.actions';
import {
  SHOPPING_ADD_TO_CART_PENDING,
  SHOPPING_CART_EXPIRATION_MONITOR_START,
  SHOPPING_CART_MONITOR_START,
  SHOPPING_GET_CART_PENDING,
  SHOPPING_GET_CART_SUCCESS,
  SHOPPING_PURCHASE_REQUEST_PENDING,
  SHOPPING_REMOVE_ALL_FROM_CART_PENDING,
  SHOPPING_REMOVE_FROM_CART_PENDING,
  SHOPPING_STARTUP,
  SHOPPING_VIEW_CART_PENDING
} from './shopping.actions.constants';
import { IShoppingAddPending, IShoppingRemoveAllPending, IShoppingRemovePending } from './shopping.actions.interfaces';
import { checkout, getCartItems } from './shopping.api';
import { ICheckOutCartModelResponse, IOrderModel } from './shopping.state.interface';
import { monitorCart, monitorCartExpiration } from './shopping.saga.monitor';
import { cartItemsSelector } from './shopping.selectors';
import { OrderStatus } from './shopping.enumeration';

const fileName = 'shopping.saga.ts';

function* startShopping(): Generator<StrictEffect, any, any> {
  yield put(shoppingGetCartPendingAction());
  yield take(SHOPPING_GET_CART_SUCCESS);
  yield put(shoppingCartMonitorStartAction());
  yield put(shoppingCartExpirationMonitorStartAction());
}
function* shoppingCartAddToCartPending(action: IShoppingAddPending): Generator<StrictEffect, any, any> {
  const items: ICartItem[] = action.meta.productList.map((cartItem) => {
    return {
      item_id: cartItem.productIdentifier,
      item_name: cartItem.lotNumber,
      price: cartItem.price,
      quantity: cartItem.sellableWeight
    };
  });

  createCartEvent(CartEventNames.addToCart, {
    currency: 'USD',
    value: items.reduce((sum: number, item: ICartItem) => sum + item.price * item.quantity, 0),
    items: items
  });
  yield put(shoppingAddToCartSucceessAction(action.meta.productList));
  yield put(notifySuccessAction(`Added ${action.meta.productList.length} item(s).`, 10000));
}

function* shoppingRemoveFromCartPending(action: IShoppingRemovePending): Generator<StrictEffect, any, any> {
  const product: IProduct = yield select(getProductByProductIdSelector(action.meta.productIdentifier));
  const items: ICartItem[] = [
    {
      item_id: product.productIdentifier,
      item_name: product.lotNumber,
      price: product.price,
      quantity: product.sellableWeight
    }
  ];
  createCartEvent(CartEventNames.removeFromCart, {
    currency: 'USD',
    value: product.sellableWeight * product.price,
    items: items
  });
  yield put(shoppingRemoveFromCartSuccessAction(action.meta.productIdentifier));
  yield call(monitorCart);
}

function* shoppingRemoveAllFromCartPending(action: IShoppingRemoveAllPending): Generator<StrictEffect, any, any> {
  const items = action.meta.cartItems.map((cartItem) => {
    return {
      item_id: cartItem.productIdentifier,
      item_name: cartItem.lotNumber,
      price: cartItem.price,
      quantity: cartItem.sellableWeight
    };
  });

  createCartEvent(CartEventNames.removeFromCart, {
    currency: 'USD',
    value: items.reduce((sum: number, item: ICartItem) => sum + item.price * item.quantity, 0),
    items: items
  });
  yield put(shoppingRemoveAllFromCartSuccessAction());
  yield call(monitorCart);
}
function* shoppingPurchaseRequestPending(): Generator<StrictEffect, any, any> {
  try {
    const isPoInError = yield select(customerPoNumberIsErrorSelector);

    if (isPoInError) {
      const dialogOptions: IAlertDialogOptions = {
        onClose: null,
        title: 'Purchase Order Info',
        message:
          'The PO Ref Number is required to complete purchase request.  Please provide it and resubmit your order.'
      };

      yield put(toggleAlertDialogAction(true, dialogOptions));
      yield put(shoppingPurchaseRequestErrorAction());
    } else {
      const loggedInUser = yield call(getUserId);

      const impersonatedUserId: string = yield select(impersonatedUserIdSelector);

      if (impersonatedUserId) {
        logger.info('Checking out with impersonated user id');
      }

      yield call(monitorCart);
      const details = yield select(cartItemsSelector);

      const updateCustomerPoFile = yield select(customerPoFileSelector);
      const updateCustomerPoNumber = yield select(customerPoNumberSelector);

      const orderModel: IOrderModel = {
        userId: impersonatedUserId || loggedInUser,
        impersonatingUserId: impersonatedUserId && loggedInUser,
        details,
        id: -1,
        orderStatusId: OrderStatus.Pending,
        updateCustomerPoNumber,
        updateCustomerPoFile
      };

      const checkoutCartResponseModel: ICheckOutCartModelResponse = yield call(checkout, orderModel);

      if (checkoutCartResponseModel.orderId === -1) {
        const dialogOptions: IAlertDialogOptions = {
          onClose: null,
          title: 'Issue with Checkout',
          message: checkoutCartResponseModel.message
        };
        yield put(toggleAlertDialogAction(true, dialogOptions));
        yield put(shoppingPurchaseRequestErrorAction());
      } else {
        yield put(toggleShoppingCartAction());
        yield call(handleCheckoutThankYou, !updateCustomerPoFile.customerPoDocumentBase64);
        yield put(shoppingPurchaseRequestSuccessAction());
        yield put(resetCustomerPoNumberAction(-1));
        yield put(resetCustomerPoFileAction(-1));
      }
      yield put(shoppingGetCartPendingAction());
      yield put(productLoadPendingAction());
      const items: ICartItem[] = details.map((cartItem: IProduct) => {
        return {
          item_id: cartItem.productIdentifier,
          item_name: cartItem.lotNumber,
          price: cartItem.price,
          quantity: cartItem.sellableWeight
        };
      });
      createCartEvent(CartEventNames.purchase, {
        currency: 'USD',
        //value: items.reduce((sum: number, item: ICartItem) => sum + item.price * item.quantity, 0),
        value: 0,
        items: items
      });
    }
  } catch (e) {
    logger.error('shoppingPurchaseRequestPending', e as Error, fileName);
    yield put(shoppingPurchaseRequestErrorAction());
    const dialogOptions: IAlertDialogOptions = {
      onClose: null,
      title: 'Technical Difficulties',
      message: 'We are having trouble processing your order at this time.  Please contact your Sales Representative.'
    };

    yield put(toggleAlertDialogAction(true, dialogOptions));
  }
}

function* handleCheckoutThankYou(missingPo: boolean): Generator<StrictEffect, any, any> {
  const missingPoText =
    '\n\nPlease note that your order does not have a purchase order file attached.  A PO will be required before Metal Exchange can fulfill your order.  You can view your order history to attach the file.';

  const dialogOptions: IAlertDialogOptions = {
    onClose: null,
    title: 'Thank you!',
    message: `Your order has been received and is pending approval. Your sales agreement and release will be available shortly.${
      missingPo ? missingPoText : ''
    }`
  };

  yield put(toggleAlertDialogAction(true, dialogOptions));
}
function* shoppingGetCartPending(): Generator<StrictEffect, any, any> {
  try {
    yield call(authWait);
    const userId = yield call(getUserId);
    const impersonatedUserId: string = yield select(impersonatedUserIdSelector);

    if (impersonatedUserId) {
      logger.info('Getting cart with impersonated user id');
    }
    const response = yield call(getCartItems, impersonatedUserId || userId);
    yield put(shoppingGetCartSuccessAction(response.payload));
  } catch (e) {
    logger.error('shoppingGetCartPending', e as Error, fileName);
    yield put(
      notifyErrorAction(
        e as Error,
        'We are having trouble getting items from your cart. Please contact your Sales Representative.'
      )
    );
  }
}

function* viewShoppingCartPending(): Generator<StrictEffect, any, any> {
  const details = yield select(cartItemsSelector);
  if (details.length !== 0) {
    const items: ICartItem[] = details.map((cartItem: IProduct) => {
      return {
        item_id: cartItem.productIdentifier,
        item_name: cartItem.lotNumber,
        price: cartItem.price,
        quantity: cartItem.sellableWeight
      };
    });
    console.log('product! ');
    createCartEvent(CartEventNames.viewCart, {
      currency: 'USD',
      value: items.reduce((sum: number, item: ICartItem) => sum + item.price * item.quantity, 0),
      //value: 0,
      items: items
    });
  }
}

function* shoppingSaga() {
  yield takeEvery(SHOPPING_ADD_TO_CART_PENDING, shoppingCartAddToCartPending);
  yield takeEvery(SHOPPING_CART_MONITOR_START, monitorCart);
  yield takeLatest(SHOPPING_STARTUP, startShopping);
  yield takeLatest(SHOPPING_REMOVE_FROM_CART_PENDING, shoppingRemoveFromCartPending);
  yield takeLatest(SHOPPING_REMOVE_ALL_FROM_CART_PENDING, shoppingRemoveAllFromCartPending);
  yield takeLatest(SHOPPING_PURCHASE_REQUEST_PENDING, shoppingPurchaseRequestPending);
  yield takeLatest(SHOPPING_GET_CART_PENDING, shoppingGetCartPending);
  yield takeLatest(SHOPPING_CART_EXPIRATION_MONITOR_START, monitorCartExpiration);
  yield takeLatest(SHOPPING_VIEW_CART_PENDING, viewShoppingCartPending);
}

export default shoppingSaga;
