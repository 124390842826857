import { createSelector } from 'reselect';

import { IState } from '../../state/store.state';
import { pricePrecision } from '../app.constants';

import { ICartItemModel, IShoppingState } from './shopping.state.interface';

const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) previous[group] = [];
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);

const shoppingSelector = (state: IState) => state.shopping;

const getCartTotalWeight = (state: IShoppingState): string => {
  const totalWeight = state.cartItems.reduce((sum, stagedItem) => sum + stagedItem.sellableWeight, 0).toFixed(0);

  return totalWeight;
};

const getCartTotalPrice = (state: IShoppingState): string => {
  const totalPrice = state.cartItems
    .reduce((sum, stagedItem) => sum + stagedItem.sellableWeight * stagedItem.price, 0)
    .toFixed(pricePrecision);

  return totalPrice;
};

export const cartItemsSelector = createSelector(
  [shoppingSelector],
  (shopping: { cartItems: ICartItemModel[] }) => shopping.cartItems
);

export const cartTotalWeightSelector = createSelector([shoppingSelector], (shopping: IShoppingState) =>
  getCartTotalWeight(shopping)
);

export const cartTotalPriceSelector = createSelector([shoppingSelector], (shopping: IShoppingState) =>
  getCartTotalPrice(shopping)
);

export const cartCountSelector = createSelector(
  [shoppingSelector],
  (shopping: { cartItems: string | any[] }) => shopping.cartItems.length
);

export const cartProductIdentifiersSelector = createSelector(
  [shoppingSelector],
  (shopping: { cartItems: ICartItemModel[] }) => shopping.cartItems.map((c: ICartItemModel) => c.productIdentifier)
);

export const isCartExpiredSelector = createSelector(
  [shoppingSelector],
  (shopping: { isCartExpired: boolean }) => shopping.isCartExpired
);
export const isCheckoutDisabledSelector = createSelector(
  [shoppingSelector],
  (shopping: IShoppingState) => shopping.cartItems.length === 0 || shopping.isCheckoutPending
);
export const cartItemsAtServerSelector = createSelector(
  [shoppingSelector],
  (shopping: IShoppingState) => shopping.productIdListServer
);
export const isCheckoutPendingSelector = createSelector(
  [shoppingSelector],
  (shopping: IShoppingState) => shopping.isCheckoutPending
);
export const isCartOpenSelector = createSelector([shoppingSelector], (shopping: IShoppingState) => shopping.isCartOpen);

export const cartItemsByWarehouseSelector = createSelector([shoppingSelector], (shopping: IShoppingState) =>
  groupBy(shopping.cartItems, (c) => c.warehouseName)
);

export const hasMultipleWarehousesSelector = createSelector(
  [shoppingSelector],
  (shopping: IShoppingState) => Object.keys(groupBy(shopping.cartItems, (c) => c.warehouseName)).length > 1
);
