import { INavigate, ISetAppName, ISetViewMode } from './layout.actions.interfaces';
import { NAVIGATE, SET_APP_NAME, SET_VIEW_MODE } from './layout.constants';

export const navigateAction = (url: string): INavigate => {
  return {
    type: NAVIGATE,
    meta: {
      url
    }
  };
};

export const setAppNameAction = (appName: string): ISetAppName => {
  return { type: SET_APP_NAME, meta: { appName } };
};

export const setViewModeAction = (isDesktopMode: boolean): ISetViewMode => {
  return { type: SET_VIEW_MODE, meta: { isDesktopMode } };
};
