import { createSelector } from 'reselect';

import { IState } from '../../state/store.state';

import { ICustomerPoState } from './customerPo.state.interface';

const customerPoSelector = (state: IState) => state.customerPo;

export const customerPoFileSelector = createSelector(
  customerPoSelector,
  (customerPo: ICustomerPoState) => customerPo.customerPoFile
);

export const customerPoFileCapturePendingSelector = createSelector(
  customerPoSelector,
  (customerPo: ICustomerPoState) => customerPo.customerPoFile.isFileCapturePending
);

export const customerPoNumberSelector = createSelector(
  customerPoSelector,
  (customerPo: ICustomerPoState) => customerPo.customerPoNumber
);
export const customerPoNumberIsErrorSelector = createSelector(
  customerPoSelector,
  (customerPo: ICustomerPoState) =>
    !customerPo.customerPoNumber.customerPoNumber || customerPo.customerPoNumber.customerPoNumber.trim() == ''
);
