import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Theme, Typography, MenuItem, Badge } from '@mui/material';
import { createStyles, makeStyles, withStyles } from '@mui/styles';
import ShoppingCart from '../../app/Shopping/Components/ShoppingCart';
import ShoppingCartBadge from '../../app/Shopping/Components/ShoppingCartBadge';
import { IState } from '../../state/store.state';
import { isCartOpenSelector } from '../../app/Shopping/shopping.selectors';
import { navigateAction } from '../layout.actions';
import { getOrderCountWithNoPurchaseOrderSelector } from '../../app/OrderHistory/order.history.selectors';
import { isSuperUserNotImpersonatedSelector } from '../../app/Customer/customer.selectors';
import { notifyWarnAction } from '../../app/Notification/notification.actions';
import paths from './router/paths';

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: -12,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      backgroundColor: 'red',
      padding: '0 2px'
    }
  })
)(Badge);

const HeaderMenu = () => {
  const dispatch = useDispatch();
  const isCartOpen = useSelector((state: IState) => isCartOpenSelector(state));
  const headerClasses = useStyles();

  const ordersWithoutPoCount = useSelector((state: IState) => getOrderCountWithNoPurchaseOrderSelector(state));
  const isSuperUserNotImpersonated = useSelector((state: IState) => isSuperUserNotImpersonatedSelector(state));

  const linkText = <Typography variant="subtitle1">ORDERS</Typography>;

  const dispatchNavigate = (url: string) => {
    dispatch(navigateAction(url));
  };
  const navigate = (url: string) => {
    dispatch(navigateAction(url));
  };
  const handlehomeClick = () => {
    navigate(paths.landing);
  };
  const handleProductNavigation = () => {
    dispatchNavigate(paths.product);
  };
  const handleOrderStatusNavigation = () => {
    if (isSuperUserNotImpersonated) {
      dispatch(notifyWarnAction('Super Users must impersonate a user to view order history'));
    } else {
      dispatchNavigate(paths.orderHistory);
    }
  };

  return (
    <div className={headerClasses.loggedInContainer}>
      <Box className={headerClasses.HomeButtons}>
        <MenuItem onClick={handlehomeClick}>HOME</MenuItem>
      </Box>
      <Box className={headerClasses.HomeButtons}>
        <MenuItem onClick={handleProductNavigation}>INVENTORY</MenuItem>
      </Box>
      <Box className={headerClasses.icon} onClick={handleOrderStatusNavigation}>
        {/* <MenuItem> */}
        {ordersWithoutPoCount > 0 ? (
          <StyledBadge badgeContent={ordersWithoutPoCount} color="secondary" className={headerClasses.icon}>
            {linkText}
          </StyledBadge>
        ) : (
          <Box display="flex">{linkText}</Box>
        )}
        {/*      ORDERS
        </MenuItem> */}
      </Box>
      <Box marginRight="50px" className={headerClasses.cart}>
        <>
          <MenuItem>
            <ShoppingCartBadge />
            {isCartOpen ? <ShoppingCart /> : <div />}
          </MenuItem>
        </>
      </Box>
    </div>
  );
};

export default HeaderMenu;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loggedInContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    cart: {
      cursor: 'pointer',
      color: theme.palette.common.white
    },
    HomeButtons: {
      cursor: 'pointer',
      fontWeight: 'bolder',
      color: theme.palette.common.white
      // margin: '20px'
    },
    icon: {
      marginRight: '20px',
      cursor: 'pointer',
      color: theme.palette.common.white,
      fontWeight: 'bolder'
    }
  })
);
