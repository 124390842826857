import * as shoppingActionInterfaces from './shopping.actions.interfaces';
import {
  SHOPPING_ADD_TO_CART_SUCCESS,
  SHOPPING_CART_TOGGLE,
  SHOPPING_GET_CART_SUCCESS,
  SHOPPING_PURCHASE_REQUEST_ERROR,
  SHOPPING_PURCHASE_REQUEST_PENDING,
  SHOPPING_PURCHASE_REQUEST_SUCCESS,
  SHOPPING_REMOVE_ALL_FROM_CART_SUCCESS,
  SHOPPING_REMOVE_FROM_CART_SUCCESS,
  SHOPPING_TOGGLE_EXPIRED_CART,
  SHOPPING_UPDATE_CART_ITEMS_AT_SERVER
} from './shopping.actions.constants';
import { IShoppingState } from './shopping.state.interface';

export const initialState: IShoppingState = {
  isCartExpired: false,
  cartItems: [],
  productIdListServer: undefined,
  isCheckoutPending: false,
  isCartOpen: false,
  cartCount: 0
};

export const handleAddToCartSuccess = (
  state: IShoppingState,
  action: shoppingActionInterfaces.IShoppingAddSuccess
): IShoppingState => {
  return {
    ...state,
    isCartExpired: false,
    cartItems: state.cartItems.concat(action.meta.productList)
  };
};

export const handleRemoveFromCartSuccess = (
  state: IShoppingState,
  action: shoppingActionInterfaces.IShoppingRemoveSuccess
): IShoppingState => {
  return {
    ...state,
    isCartExpired: false,
    cartItems: state.cartItems.filter((ci) => ci.productIdentifier != action.payload.productIdentifier)
  };
};
export const handlePurchaseRequestPending = (state: IShoppingState): IShoppingState => {
  return { ...state, isCheckoutPending: true };
};

export const handlePurchaseRequestError = (state: IShoppingState): IShoppingState => {
  return { ...state, isCheckoutPending: false };
};

export const handlePurchaseRequestSuccess = (state: IShoppingState): IShoppingState => {
  return {
    ...state,
    cartItems: [],
    isCartExpired: false,
    isCheckoutPending: false
  };
};

export const handleRemoveAllFromCartSuccess = (state: IShoppingState): IShoppingState => {
  return {
    ...state,
    cartItems: [],
    isCartExpired: false
  };
};

export const handleToggleExpiredCart = (
  state: IShoppingState,
  action: shoppingActionInterfaces.IShoppingSetExpiredCart
): IShoppingState => {
  return { ...state, isCartExpired: action.meta.isExpired };
};

export const handleGetCartSuccess = (
  state: IShoppingState,
  action: shoppingActionInterfaces.IShoppingGetCartSuccess
): IShoppingState => ({
  ...state,
  cartItems: action.payload.cartItems
});

export const handleUpdateCartItemsAtServer = (
  state: IShoppingState,
  action: shoppingActionInterfaces.IUpdateCartItemsAtServer
): IShoppingState => {
  return {
    ...state,
    productIdListServer: action.meta.productIdListServer
  };
};

const toggleShoppingCart = (state: IShoppingState): IShoppingState => {
  return { ...state, isCartOpen: !state.isCartOpen };
};

const shoppingReducer = (
  state = initialState,
  action:
    | shoppingActionInterfaces.IShoppingAddSuccess
    | shoppingActionInterfaces.IShoppingRemovePending
    | shoppingActionInterfaces.IShoppingRemoveSuccess
    | shoppingActionInterfaces.IShoppingPurchaseRequestPending
    | shoppingActionInterfaces.IShoppingPurchaseRequestSuccess
    | shoppingActionInterfaces.IShoppingPurchaseRequestError
    | shoppingActionInterfaces.IShoppingSetExpiredCart
    | shoppingActionInterfaces.IShoppingRemoveAllSuccess
    | shoppingActionInterfaces.IShoppingGetCartPending
    | shoppingActionInterfaces.IShoppingGetCartSuccess
    | shoppingActionInterfaces.IUpdateCartItemsAtServer
    | shoppingActionInterfaces.IToggleShoppingCart
): IShoppingState => {
  switch (action.type) {
    case SHOPPING_ADD_TO_CART_SUCCESS:
      return handleAddToCartSuccess(state, action);
    case SHOPPING_REMOVE_FROM_CART_SUCCESS:
      return handleRemoveFromCartSuccess(state, action);
    case SHOPPING_REMOVE_ALL_FROM_CART_SUCCESS:
      return handleRemoveAllFromCartSuccess(state);
    case SHOPPING_TOGGLE_EXPIRED_CART:
      return handleToggleExpiredCart(state, action);
    case SHOPPING_PURCHASE_REQUEST_PENDING:
      return handlePurchaseRequestPending(state);
    case SHOPPING_PURCHASE_REQUEST_SUCCESS:
      return handlePurchaseRequestSuccess(state);
    case SHOPPING_PURCHASE_REQUEST_ERROR:
      return handlePurchaseRequestError(state);
    case SHOPPING_GET_CART_SUCCESS:
      return handleGetCartSuccess(state, action);
    case SHOPPING_UPDATE_CART_ITEMS_AT_SERVER:
      return handleUpdateCartItemsAtServer(state, action);
    case SHOPPING_CART_TOGGLE:
      return toggleShoppingCart(state);
  }

  return state;
};

export default shoppingReducer;
