import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, ButtonGroup, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { downloadFilePendingAction } from '../../FileDownload/fileDownload.actions';
import { IState } from '../../../state/store.state';
import { customerPoFileSelector } from '../customerPo.selectors';
import { saveBase64ToDisk } from '../../FileDownload/fileDownload.utilities';
import { OrderDocumentType } from '../../OrderHistory/order.history.enums';
interface IProps {
  readonly inputControlId: string;
  readonly url?: string;
  readonly fileName: string;
}
const useStyles = makeStyles(() => ({
  checkBox: {
    color: 'green'
  }
}));

const CustomerPoUploadMenuEdit = (props: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const customerPo = useSelector((state: IState) => customerPoFileSelector(state));

  const handleDownload = () => () => {
    if (props.url) {
      dispatch(downloadFilePendingAction(props.url, props.fileName, OrderDocumentType.CustomerPo));
    } else if (customerPo.customerPoDocumentBase64) {
      saveBase64ToDisk(props.fileName, customerPo.customerPoDocumentBase64, 'image/*');
    }
  };

  return (
    <Grid container={true} spacing={0} alignItems="center" justifyContent="center">
      <Grid item xs={4}>
        <CheckCircleIcon className={classes.checkBox} fontSize="large" />
      </Grid>
      <Grid item xs={8}>
        <ButtonGroup orientation="vertical" size="small" variant="text" aria-label="text primary button group">
          <Button onClick={handleDownload()} size="small">
            View
          </Button>
          <label htmlFor={props.inputControlId}>
            <Button component="span" size="small">
              Change
            </Button>
          </label>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};

export default CustomerPoUploadMenuEdit;
