import * as actionInterfaces from './order.history.actions.interfaces';
import {
  GET_ORDER_HISTORY_ERROR,
  GET_ORDER_HISTORY_PENDING,
  GET_ORDER_HISTORY_SUCCESS,
  SET_BEGIN_FILTER_DATE,
  SET_END_FILTER_DATE,
  SORT,
  TOGGLE_ORDER_DOCUMENTS_DIALOG,
  TOGGLE_ORDER_DOCUMENTS_DIALOG_MOBILE,
  UPDATE_CUSTOMER_PO_FILE_ERROR,
  UPDATE_CUSTOMER_PO_FILE_PENDING,
  UPDATE_CUSTOMER_PO_FILE_SUCCESS,
  UPDATE_CUSTOMER_PO_NUMBER_ERROR,
  UPDATE_CUSTOMER_PO_NUMBER_PENDING,
  UPDATE_CUSTOMER_PO_NUMBER_SUCCESS,
  TOGGLE_FILTER_DIALOG,
  RESET_FILTERS,
  SEARCH,
  GET_PACKING_LIST_FILE_PENDING,
  GET_PACKING_LIST_FILE_SUCCESS,
  GET_PACKING_LIST_FILE_ERROR,
  TOGGLE_ORDER_HISTORY_ISDOCUMENT_CHECKED,
  TOGGLE_ALL_ORDER_HISTORY_ISDOCUMENT_CHECKED,
  DOWNLOAD_CHECKED_DOCUMENT_PENDING
} from './order.history.actions.constants';
import { IOrderHistory, IOrderHistoryDocumentModel } from './order.history.state.interfaces';

export const getOrderHistoryPendingAction = (): actionInterfaces.IGetOrderHistoryPending => ({
  type: GET_ORDER_HISTORY_PENDING
});

export const getOrderHistorySuccessAction = (orders: IOrderHistory[]): actionInterfaces.IGetOrderHistorySuccess => ({
  type: GET_ORDER_HISTORY_SUCCESS,
  payload: orders
});

export const getOrderHistoryErrorAction = (): actionInterfaces.IGetOrderHistoryError => ({
  type: GET_ORDER_HISTORY_ERROR
});

export const updateCustomerPoFilePendingAction = (): actionInterfaces.IUpdateCustomerPoFilePending => ({
  type: UPDATE_CUSTOMER_PO_FILE_PENDING
});

export const updateCustomerPoFileSuccessAction = (): actionInterfaces.IUpdateCustomerPoFileSuccess => ({
  type: UPDATE_CUSTOMER_PO_FILE_SUCCESS
});

export const updateCustomerPoFileErrorAction = (): actionInterfaces.IUpdateCustomerPoFileError => ({
  type: UPDATE_CUSTOMER_PO_FILE_ERROR
});

export const updateCustomerPoNumberPendingAction = (): actionInterfaces.IUpdateCustomerPoNumberPending => ({
  type: UPDATE_CUSTOMER_PO_NUMBER_PENDING
});

export const updateCustomerPoNumberSuccessAction = (): actionInterfaces.IUpdateCustomerPoNumberSuccess => ({
  type: UPDATE_CUSTOMER_PO_NUMBER_SUCCESS
});

export const updateCustomerPoNumberErrorAction = (): actionInterfaces.IUpdateCustomerPoNumberError => ({
  type: UPDATE_CUSTOMER_PO_NUMBER_ERROR
});

export const toggleOrderDocumentsDialogAction = (orderId: number): actionInterfaces.IToggleOrderDocumentsDialog => ({
  type: TOGGLE_ORDER_DOCUMENTS_DIALOG,
  meta: { orderId }
});

export const toggleOrderHistoryIsDocumentCheckedAction = (
  documentId: number
): actionInterfaces.IToggleOrderHistoryIsDocumentChecked => ({
  type: TOGGLE_ORDER_HISTORY_ISDOCUMENT_CHECKED,
  meta: { documentId }
});

export const toggleAllOrderHisotryIsDocumentCheckedAction = (
  checkedState: boolean
): actionInterfaces.IToggleAllOrderHistoryIsDocumentChecked => ({
  type: TOGGLE_ALL_ORDER_HISTORY_ISDOCUMENT_CHECKED,
  meta: { checkedState }
});

export const toggleOrderDocumentsDialogMobileAction = (
  orderId: number
): actionInterfaces.IToggleOrderDocumentsDialogMobile => ({
  type: TOGGLE_ORDER_DOCUMENTS_DIALOG_MOBILE,
  meta: { orderId }
});

export const downloadCheckedDocumentsPendingAction = (
  documents: IOrderHistoryDocumentModel[]
): actionInterfaces.IDownloadCheckedDocumentsPending => ({
  type: DOWNLOAD_CHECKED_DOCUMENT_PENDING,
  meta: { documents }
});

export const downloadCheckedDocumentsSuccessAction = (
  message: string
): actionInterfaces.IDownloadCheckedDocumentsSuccess => ({
  type: DOWNLOAD_CHECKED_DOCUMENT_PENDING,
  meta: { message }
});

export const downloadCheckedDocumentsErrorAction = (error: Error): actionInterfaces.IDownloadCheckedDocumentsError => ({
  type: DOWNLOAD_CHECKED_DOCUMENT_PENDING,
  meta: { error }
});

export const sortAction = (columnId: string, isDescending: boolean): actionInterfaces.ISort => ({
  type: SORT,
  meta: { columnId, isDescending }
});

export const setBeginDateFilterAction = (beginDate: Date): actionInterfaces.ISetBeginDateFilter => ({
  type: SET_BEGIN_FILTER_DATE,
  meta: { beginDate }
});

export const setEndDateFilterAction = (endDate: Date): actionInterfaces.ISetEndDateFilter => ({
  type: SET_END_FILTER_DATE,
  meta: { endDate }
});

export const searchAction = (searchText: string): actionInterfaces.ISearch => ({
  type: SEARCH,
  meta: { searchText }
});

export const toggleFilterDialogAction = (): actionInterfaces.IToggleFilterDialog => ({ type: TOGGLE_FILTER_DIALOG });

export const resetFiltersAction = (): actionInterfaces.IResetFilters => ({ type: RESET_FILTERS });

export const getPackingListFileActionPending = (
  url: string,
  fileName: string
): actionInterfaces.IGetPackingListFilePending => ({
  type: GET_PACKING_LIST_FILE_PENDING,
  meta: { url, fileName }
});

export const getPackingListFileActionSuccess = (file: any): actionInterfaces.IGetPackingListFileSuccess => ({
  type: GET_PACKING_LIST_FILE_SUCCESS,
  payload: { file }
});

export const getPackingListFileActionError = (message: Error): actionInterfaces.IGetPackingListFileError => ({
  type: GET_PACKING_LIST_FILE_ERROR,
  meta: { message }
});
