import {
  GET_AUTHORIZATION_ERROR,
  GET_AUTHORIZATION_PENDING,
  GET_AUTHORIZATION_SUCCESS
} from './authorization.actions.constants';
import {
  IGetAuthorizationError,
  IGetAuthorizationPending,
  IGetAuthorizationSuccess
} from './authorization.actions.interface';
import { AuthorizationStatus } from './authorization.enum';
import { IAadGroup } from './authorization.state.interface';

export const getAuthorizationPending = (): IGetAuthorizationPending => ({
  type: GET_AUTHORIZATION_PENDING
});

export const getAuthorizationSuccess = (
  authorizationStatus: AuthorizationStatus,
  groups: IAadGroup[]
): IGetAuthorizationSuccess => ({
  type: GET_AUTHORIZATION_SUCCESS,
  payload: { authorizationStatus, groups }
});

export const getAuthorizationError = (): IGetAuthorizationError => ({
  type: GET_AUTHORIZATION_ERROR
});
