import React from 'react';

import { createStyles, makeStyles } from '@mui/styles';

import HeaderHome from './HeaderHome';
import HeaderMenu from './HeaderMenu';

const header = () => {
  const headerClasses = useStyles();

  return (
    <div className={headerClasses.headerContext}>
      <HeaderHome />
      <div className={headerClasses.rightContainer}>
        <HeaderMenu />
      </div>
    </div>
  );
};

export default header;

const useStyles = makeStyles(() =>
  createStyles({
    rightContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end'
    },
    headerContext: {
      // TODO: create mobile style using mobile image
      display: 'flex',
      backgroundImage: `url('../../../../images/vie/desktop/blue-background-bar-desktop.png')`,
      backgroundSize: 'cover',
      borderRadius: '0px',
      height: '100%'
    }
  })
);
